import {
  IntegrationPatientSortableFields,
  RIntegrationPatient,
} from '@counsel-project/counsel-ehr-api'
import { useCallback, useEffect, useState } from 'react'
import IntegrationPatientTable from '../../../components/tables/IntegrationPatientTable'
import { ehrRequest } from '../../../util/api/ehr-api'
import checkToken from '../../../util/auth/checkToken'
import handleError from '../../../util/handleError'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import BackIcon from '@mui/icons-material/ArrowBackRounded'

type IntegrationPatientsDisplayProps = {
  integrationId: string
}

const IntegrationPatientsDisplay = ({ integrationId }: IntegrationPatientsDisplayProps) => {
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState<IntegrationPatientSortableFields>('name')
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc')
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [patients, setPatients] = useState<RIntegrationPatient[]>([])
  const [selectedPatient, setSelectedPatient] = useState<RIntegrationPatient | null>(null)

  const populatePatientsTotal = useCallback(async () => {
    try {
      await checkToken()

      const { count } = await ehrRequest.integrations.patients.countByIntegration({
        token: '',
        integrationId,
      })

      setTotal(count)
    } catch (err) {
      handleError(err)
    }
  }, [integrationId])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populatePatientsTotal()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populatePatientsTotal])

  const populatePatients = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const { results } = await ehrRequest.integrations.patients.listByIntegration({
        token: '',
        integrationId,
        offset: page * limit,
        limit,
        search: search
          ? {
              and: [{ name: search, $fuzzy: true }],
            }
          : undefined,
        sort,
        direction,
      })

      setPatients(results)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [integrationId, page, limit, search, sort, direction])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populatePatients()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populatePatients])

  if (selectedPatient) {
    return (
      <Box>
        <Grid container alignItems="center" sx={{ mb: 2 }} spacing={2}>
          <Grid item>
            <Button onClick={() => setSelectedPatient(null)}>
              <BackIcon />
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="h6">{selectedPatient.name}</Typography>
          </Grid>
        </Grid>
        {!!selectedPatient?.metadata?.mrNumber && (
          <Typography variant="body1">
            <strong>MRN:</strong> {(selectedPatient?.metadata?.mrNumber as string) || 'N/A'}
          </Typography>
        )}
        {!!selectedPatient?.metadata?.locationName && (
          <Typography variant="body1">
            <strong>Location:</strong>{' '}
            {(selectedPatient?.metadata?.locationName as string) || 'N/A'}{' '}
            {selectedPatient?.metadata?.locationId
              ? `(${selectedPatient?.metadata?.locationId})`
              : ''}
          </Typography>
        )}
        {!!selectedPatient?.identifier && (
          <Typography variant="body1">
            <strong>Identifier:</strong> {(selectedPatient?.identifier as string) || 'N/A'}
          </Typography>
        )}
        {!!selectedPatient?.updatedAt && (
          <Typography variant="body1">
            <strong>Last Synced:</strong> {selectedPatient?.updatedAt}
          </Typography>
        )}
      </Box>
    )
  }

  return (
    <IntegrationPatientTable
      rows={patients}
      page={page}
      rowsPerPage={limit}
      total={total}
      loaded={!loading}
      sort={sort}
      direction={direction}
      onChangeSort={(sort, dir) => {
        setSort(sort)
        setDirection(dir)
      }}
      onChangePage={setPage}
      onChangeRowsPerPage={setLimit}
      onSubmitSearch={setSearch}
      onClickRow={setSelectedPatient}
      rowsPerPageOptions={[]}
    />
  )
}

export default IntegrationPatientsDisplay
