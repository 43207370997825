import { useEffect, useRef, useState } from 'react'

type TwoDAudioVisualizerProps = {
  onGetAudioLevel: () => number
  width: number
  height: number
  backgroundColor: string
  barColor: string
  filledColor: string
  partiallyFilledColor: string
}

const TwoDAudioVisualizer = ({
  onGetAudioLevel,
  width,
  height,
  backgroundColor,
  barColor,
  filledColor,
  partiallyFilledColor,
}: TwoDAudioVisualizerProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const loudestAudioLevelRef = useRef(0)
  const lastLoudestAudioLevelRef = useRef(new Date().getTime())
  const previousAudioLevelRef = useRef(0)
  const [isActive, setIsActive] = useState(true)

  useEffect(() => {
    if (!onGetAudioLevel || !canvasRef.current) return

    const canvas = canvasRef.current
    const canvasCtx = canvas.getContext('2d', { alpha: false })
    if (!canvasCtx) return

    const barCount = 28 // Total number of bars (should be even for symmetry)
    const barWidth = width / barCount
    const centerY = height / 2

    // Pre-calculate bar positions
    const barPositions = new Array(barCount).fill(0).map((_, i) => {
      return i * barWidth // Simply multiply the index by barWidth to go left to right
    })

    let animationFrameId: number

    const draw = () => {
      if (!isActive) {
        animationFrameId = requestAnimationFrame(draw)
        return
      }

      canvasCtx.fillStyle = backgroundColor
      canvasCtx.fillRect(0, 0, width, height)

      const currentAudioLevel = onGetAudioLevel()
      const smoothingFactor = 0.15 // Adjust this value between 0 and 1 (lower = smoother)
      const smoothedAudioLevel =
        previousAudioLevelRef.current +
        (currentAudioLevel - previousAudioLevelRef.current) * smoothingFactor
      previousAudioLevelRef.current = smoothedAudioLevel

      const loudestAudioLevel = loudestAudioLevelRef.current

      if (new Date().getTime() - lastLoudestAudioLevelRef.current > 20000) {
        loudestAudioLevelRef.current = 0.15
      }

      if (smoothedAudioLevel > loudestAudioLevel) {
        loudestAudioLevelRef.current = smoothedAudioLevel
        lastLoudestAudioLevelRef.current = new Date().getTime()
      }

      const valuePer = loudestAudioLevel / barCount

      for (let i = 0; i < barCount; i++) {
        const x = barPositions[i]

        if (x + barWidth > width) {
          continue
        }

        const value = valuePer * i

        if (value > smoothedAudioLevel - 0.005) {
          // Draw the "always present" part of the bar with a dim color
          canvasCtx.fillStyle = barColor
          canvasCtx.fillRect(x, centerY - height, barWidth - 1, height)
          canvasCtx.fillRect(x, centerY, barWidth - 1, height)
          continue
        }

        if (value > smoothedAudioLevel - 0.02) {
          // Draw the partially filled part of the bar
          canvasCtx.fillStyle = partiallyFilledColor
          canvasCtx.fillRect(x, centerY - height, barWidth - 1, height)
          canvasCtx.fillRect(x, centerY, barWidth - 1, height)
          continue
        }

        // Draw the colored part of the bar
        canvasCtx.fillStyle = filledColor
        canvasCtx.fillRect(x, centerY - height, barWidth - 1, height)
        canvasCtx.fillRect(x, centerY, barWidth - 1, height)
      }

      animationFrameId = requestAnimationFrame(draw)
    }

    draw()

    return () => {
      cancelAnimationFrame(animationFrameId)
    }
  }, [
    width,
    height,
    barColor,
    filledColor,
    backgroundColor,
    isActive,
    partiallyFilledColor,
    onGetAudioLevel,
  ])

  // Pause rendering when the tab is not visible
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsActive(!document.hidden)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return <canvas ref={canvasRef} width={width} height={height} />
}

export default TwoDAudioVisualizer
