import PageContainer from './components/layout/PageContainer'
import Typography from '@mui/material/Typography'

const CloseWindow = () => {
  return (
    <PageContainer>
      <Typography variant="body1" sx={{ mt: 12 }} textAlign="center">
        You can safely close this window.
      </Typography>
    </PageContainer>
  )
}

export default CloseWindow
