import { Recorder } from '@counsel-project/client-utils'
import CheckIcon from '@mui/icons-material/CheckRounded'
import RefreshIcon from '@mui/icons-material/RefreshRounded'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ClosableDialog from '../../components/ClosableDialog'
import MicrophonePermissionDialog from '../../components/audio/MicrophonePermissionDialog'
import getIsMobile from '../../util/getIsMobile'
import log from '../../util/logging'
import useAvailableAudioDevices from '../../util/recording/useAvailableAudioDevices'
import theme from '../../util/theme'
import TwoDAudioVisualizer from './TwoDAudioVisualizer'

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
const isMacOS = navigator.userAgent.includes('Macintosh')

export type DeviceOptionsSelected = {
  deviceId?: string
  deviceLabel?: string
  secondaryDeviceId?: string
  secondaryDeviceLabel?: string
  browserAudio?: boolean
}

type AudioSetupDialogProps = {
  open: boolean
  disabled?: boolean
  dictation?: boolean
  onClose: () => void
  onOptionsSelected: (device?: DeviceOptionsSelected) => void
}

const AudioSetupDialog = ({
  open,
  disabled,
  dictation,
  onClose,
  onOptionsSelected,
}: AudioSetupDialogProps) => {
  const { devices, permissionState, requestPermission } = useAvailableAudioDevices()
  const [selectedDevice, setSelectedDevice] = useState<MediaDeviceInfo | null>(null)
  const [secondaryDevice, setSecondaryDevice] = useState<MediaDeviceInfo | null>(null)
  const [primaryAnalyser, setPrimaryAnalyser] = useState<AnalyserNode | null>(null)
  const [secondaryAnalyzer, setSecondaryAnalyzer] = useState<AnalyserNode | null>(null)
  const [usingHeadphones, setUsingHeadphones] = useState(false)
  const [telehealthMethod, setTelehealthMethod] = useState<'browser' | 'app' | null>(null)

  const isMobile = useMemo(() => getIsMobile(), [])

  console.log(devices)

  const agentIsWorking = useMemo(() => {
    return (
      secondaryDevice &&
      secondaryDevice.label.includes('CNAI Audio Device') &&
      isMacOS &&
      telehealthMethod === 'app' &&
      usingHeadphones
    )
  }, [secondaryDevice, telehealthMethod, usingHeadphones])

  const includeSecondaryDevice = useMemo(() => {
    return (
      secondaryDevice &&
      secondaryDevice.label.includes('CNAI Audio Device') &&
      isMacOS &&
      (telehealthMethod === 'app' || isSafari) &&
      usingHeadphones &&
      !isMobile
    )
  }, [secondaryDevice, telehealthMethod, usingHeadphones, isMobile])

  const enableStartSession = useMemo(() => {
    if (isMobile) return true
    if (telehealthMethod === 'browser' && isSafari) return false
    if (telehealthMethod === 'app' && !isMacOS) return false
    if (telehealthMethod === 'app' && !secondaryDevice?.label?.includes('CNAI Audio Device'))
      return false
    if (usingHeadphones && !telehealthMethod && !isSafari) return false
    if (disabled) return false
    return true
  }, [secondaryDevice, telehealthMethod, usingHeadphones, disabled, isMobile])

  const handleSubmit = useCallback(async () => {
    await Recorder.stopRecording()
    onOptionsSelected({
      deviceId: selectedDevice?.deviceId,
      deviceLabel: selectedDevice?.label,
      secondaryDeviceId: includeSecondaryDevice ? secondaryDevice?.deviceId : undefined,
      secondaryDeviceLabel: includeSecondaryDevice ? secondaryDevice?.label : undefined,
      browserAudio: telehealthMethod === 'browser',
    })
  }, [selectedDevice, secondaryDevice, onOptionsSelected, includeSecondaryDevice, telehealthMethod])

  useEffect(() => {
    if (!open) return
    if (isMobile) return
    requestPermission()
  }, [open, requestPermission, isMobile])

  const handleStartRecording = useCallback(async () => {
    try {
      await Recorder.stopRecording()

      setPrimaryAnalyser(null)
      setSecondaryAnalyzer(null)

      console.log('deviceId', selectedDevice?.deviceId)
      console.log('selectedDeviceLabel', selectedDevice?.label)
      console.log('secondaryDeviceId', secondaryDevice?.deviceId)
      console.log('secondaryDeviceLabel', secondaryDevice?.label)

      const {
        device,
        secondaryAnalyser: newSecondaryAnalyzer,
        primaryAnalyser: newPrimaryAnalyser,
      } = await Recorder.startRecording({
        sampleRate: 16000,
        channels: 1,
        onError: (err) => {
          log.error('LIVE - setup error - ', err)
        },
        onDebug: (msg) => {
          log.info('LIVE - setup debug - ', msg)
        },
        deviceId: selectedDevice?.deviceId,
        secondaryDeviceId: secondaryDevice?.deviceId,
      })

      console.log('recording device', device)

      if (device) {
        setSelectedDevice((prev) => (prev ? prev : device))
      }

      console.log('newSecondaryAnalyzer', newSecondaryAnalyzer)
      console.log('newPrimaryAnalyser', newPrimaryAnalyser)

      setSecondaryAnalyzer(newSecondaryAnalyzer)
      setPrimaryAnalyser(newPrimaryAnalyser)
    } catch (err) {
      log.error('LIVE - setup error - ', err)
    }
  }, [selectedDevice, secondaryDevice])

  useEffect(() => {
    if (!open) return

    const timeout = setTimeout(() => {
      if (!open) return
      handleStartRecording()
    }, 10)

    return () => {
      Recorder.stopRecording()
      clearTimeout(timeout)
    }
  }, [open, handleStartRecording])

  useEffect(() => {
    if ((telehealthMethod !== 'app' && !isSafari) || !usingHeadphones) {
      setSecondaryDevice(null)
      return
    }

    const loopbackDevice = devices.find((d) => d.label.includes('CNAI Audio Device'))
    console.log('loopbackDevice', loopbackDevice)
    if (loopbackDevice) {
      setSecondaryDevice((prev) => (prev ? prev : loopbackDevice))
    }
  }, [devices, telehealthMethod, usingHeadphones])

  const handleClose = useCallback(async () => {
    await Recorder.stopRecording()
    onClose()
  }, [onClose])

  if (open && permissionState === 'denied') {
    return <MicrophonePermissionDialog open={true} onClose={handleClose} />
  }

  return (
    <ClosableDialog open={open} onClose={handleClose} titleText="Confirm Start Session">
      <DialogContent>
        {!isMobile && (
          <Grid container alignItems="end" spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" fontWeight={500} gutterBottom>
                Clinician Audio
              </Typography>
              <Select
                value={selectedDevice?.deviceId || null}
                onChange={(e) =>
                  setSelectedDevice(devices.find((d) => d.deviceId === e.target.value) || null)
                }
                placeholder="Select Device"
                fullWidth
                inputProps={{
                  placeholder: 'Select Device',
                }}
                renderValue={(value) => {
                  if (!value) return 'Select Device'
                  const device = devices.find((d) => d.deviceId === value)
                  return device?.label || 'Select Device'
                }}
              >
                {devices
                  .filter((d) => secondaryDevice?.deviceId !== d.deviceId && !!d.label)
                  .map((d) => (
                    <MenuItem key={d.deviceId} value={d.deviceId}>
                      {d.label}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              {primaryAnalyser ? (
                <TwoDAudioVisualizer
                  onGetAudioLevel={() => Recorder.getPrimaryAudioLevel()}
                  width={200}
                  height={40}
                  backgroundColor="white"
                  barColor={theme.palette.background.default}
                  filledColor={theme.palette.primary.main}
                  partiallyFilledColor={theme.palette.primary.light}
                />
              ) : (
                <Box sx={{ height: 48 }} />
              )}
            </Grid>

            {secondaryDevice && (
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" fontWeight={500} gutterBottom>
                  Client Audio
                </Typography>
                <Paper
                  sx={{
                    p: 1.5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  variant="outlined"
                >
                  <Typography variant="body2" color="text.secondary">
                    CNAI Audio Device
                  </Typography>
                  <IconButton size="small" onClick={() => handleStartRecording()} color="primary">
                    <RefreshIcon />
                  </IconButton>
                </Paper>
              </Grid>
            )}
            {secondaryDevice && (
              <Grid item xs={12} sm={6}>
                {secondaryAnalyzer ? (
                  <TwoDAudioVisualizer
                    onGetAudioLevel={() => Recorder.getSecondaryAudioLevel()}
                    width={200}
                    height={40}
                    backgroundColor="white"
                    barColor={theme.palette.background.default}
                    filledColor={theme.palette.primary.main}
                    partiallyFilledColor={theme.palette.primary.light}
                  />
                ) : (
                  <Box sx={{ height: 48 }} />
                )}
              </Grid>
            )}
          </Grid>
        )}

        {!isMobile && !dictation && (
          <Box sx={{ mb: 2, mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item container>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={usingHeadphones}
                      onChange={(e) => setUsingHeadphones(e.target.checked)}
                    />
                  }
                  label={<Typography variant="body2">Using Telehealth + Headphones?</Typography>}
                />
              </Grid>
              {usingHeadphones && (
                <>
                  {!isSafari && (
                    <>
                      <Grid item xs={6}>
                        <Button
                          variant={telehealthMethod === 'browser' ? 'contained' : 'text'}
                          color="primary"
                          fullWidth
                          onClick={() => setTelehealthMethod('browser')}
                        >
                          Using Telehealth In-Browser
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant={telehealthMethod === 'app' ? 'contained' : 'text'}
                          color="primary"
                          fullWidth
                          onClick={() => setTelehealthMethod('app')}
                        >
                          Using Telehealth In-App
                        </Button>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    {telehealthMethod === 'browser' ? (
                      <Typography variant="body2" color="text.secondary">
                        Use this option if you are running your telehealth session through your
                        browser (Zoom through chrome etc.). You will be prompted to share the tab or
                        screen that your Telehealth session is in.
                      </Typography>
                    ) : telehealthMethod === 'app' ? (
                      <Typography variant="body2" color="text.secondary">
                        Use this option if you are running your telehealth session through an
                        application on your desktop like Zoom.
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Collapse in={telehealthMethod === 'browser' && usingHeadphones}>
                      <Box>
                        <Typography
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                          sx={{ mt: 2 }}
                        >
                          Ensure that the share audio option is available and checked at the bottom
                          right.
                          <br />
                          If you share a window the session will not capture.
                        </Typography>
                        <img
                          src="/share-tab-screenshot.png"
                          alt="Share Tab Screenshot"
                          style={{
                            height: '300px',
                            marginTop: 16,
                            borderRadius: 4,
                            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                          }}
                        />
                      </Box>
                    </Collapse>
                    <Collapse in={(telehealthMethod === 'app' || isSafari) && usingHeadphones}>
                      <Box>
                        {!secondaryDevice?.label?.includes('CNAI Input') ? (
                          <Typography variant="body2" color="text.primary" fontWeight={500}>
                            Please run the CNAI telehealth agent. You can download it{' '}
                            <Link href="/test-driver" target="_blank">
                              here
                            </Link>
                            <br />
                            When the agent is detected and configured, you will see it here.
                          </Typography>
                        ) : agentIsWorking ? (
                          <Box sx={{ display: 'flex', alignItems: 'end' }}>
                            <CheckIcon
                              fontSize="small"
                              color="success"
                              sx={{ mr: 1, display: 'inline-block' }}
                            />
                            <Typography variant="body2" color="text.primary" fontWeight={500}>
                              CNAI telehealth agent detected.
                            </Typography>
                          </Box>
                        ) : null}
                      </Box>
                    </Collapse>
                  </Grid>
                </>
              )}
              {isSafari && usingHeadphones && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="error" fontWeight={500}>
                    It is recommended to use Chrome for Clinical Notes AI during telehealth
                    sessions.
                  </Typography>
                </Grid>
              )}
              {/* {usingHeadphones && telehealthMethod === 'app' && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.primary" fontWeight={500}>
                    If you are not seeing audio being detected, you can always run the CNAI
                    telehealth agent script again. You can download it{' '}
                    <Link href="/cnai-macos-silicon.sh" target="_blank">
                      here
                    </Link>
                  </Typography>
                </Grid>
              )} */}
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!enableStartSession}
        >
          Start Session
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default AudioSetupDialog
