import { ApiError, request } from '@counsel-project/client-utils'
import {
  LoginUser2FAResponse,
  LoginUserGenerateOTCResponse,
  LoginUserSuccessResponse,
  LoginUserWith2FAEmailOptions,
  LoginUserWith2FAEmailResponse,
  LoginUserWith2FAPhoneOptions,
  LoginUserWith2FAPhoneResponse,
  LoginUserWithAppleOptions,
  LoginUserWithAppleResponse,
  LoginUserWithEmailOptions,
  LoginUserWithGoogleOptions,
  LoginUserWithGoogleResponse,
  LoginUserWithMicrosoftOptions,
  LoginUserWithMicrosoftResponse,
  LoginUserWithTokenResponse,
  UserUpdateEmailOptions,
  UserUpdateEmailResponse,
  UserVerifyUpdateEmailOptions,
  UserVerifyUpdateEmailResponse,
} from '@counsel-project/counsel-auth-api'
import { posthogIdentify } from '../tracking/posthog'
import { setStore } from '../store/auth'
import {
  LoginAdminUserOptions,
  LoginAdminUserResponse,
} from '@counsel-project/counsel-auth-api/dist/node_api/endpoints/admin/users'

const url = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/api' : '/api'

type LoginEmailResponse =
  | Omit<LoginUserSuccessResponse, 'token' | 'refreshToken'>
  | LoginUser2FAResponse

const loginWithEmail = async (options: LoginUserWithEmailOptions): Promise<LoginEmailResponse> => {
  const response = await request({
    method: 'POST',
    url: url + '/login/email',
    body: options,
    withCredentials: true,
  })

  if (response.status !== 200) {
    throw new ApiError('Invalid Credentials')
  }

  const { user } = response.body

  if (user) {
    setStore({
      user,
    })

    posthogIdentify(
      user._id,
      user.email || '',
      user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName || ''
    )
  }

  return response.body
}

type LoginEmail2FAResponse = Omit<LoginUserWith2FAEmailResponse, 'token' | 'refreshToken'>

const loginWithEmail2FA = async (
  options: LoginUserWith2FAEmailOptions
): Promise<LoginEmail2FAResponse> => {
  const response = await request({
    method: 'POST',
    url: url + '/login/email-2fa',
    body: options,
    withCredentials: true,
  })

  if (response.status !== 200) {
    throw new ApiError('Invalid Credentials')
  }

  const { user } = response.body

  if (user) {
    setStore({
      user,
    })

    posthogIdentify(
      user._id,
      user.email || '',
      user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName || ''
    )
  }

  return response.body
}

type LoginPhone2FAResponse = Omit<LoginUserWith2FAPhoneResponse, 'token' | 'refreshToken'>

const loginWithPhone2FA = async (
  options: LoginUserWith2FAPhoneOptions
): Promise<LoginPhone2FAResponse> => {
  const response = await request({
    method: 'POST',
    url: url + '/login/phone-2fa',
    body: options,
    withCredentials: true,
  })

  if (response.status !== 200) {
    throw new ApiError('Invalid Credentials')
  }

  const { user } = response.body

  if (user) {
    setStore({
      user,
    })

    posthogIdentify(
      user._id,
      user.email || '',
      user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName || ''
    )
  }

  return response.body
}

type LoginWithGoogleResponse = Omit<LoginUserWithGoogleResponse, 'token' | 'refreshToken'>

const loginWithGoogle = async (
  options: LoginUserWithGoogleOptions
): Promise<LoginWithGoogleResponse> => {
  const response = await request({
    method: 'POST',
    url: url + '/login/google',
    body: options,
    withCredentials: true,
  })

  if (response.status !== 200) {
    throw new ApiError('Invalid Credentials')
  }

  const { user } = response.body
  console.log(`Auth with google response: ${user}`)

  if (user) {
    setStore({
      user,
    })

    posthogIdentify(
      user._id,
      user.email || '',
      user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName || ''
    )
  }

  return response.body
}

type LoginWithAppleResponse = Omit<LoginUserWithAppleResponse, 'token' | 'refreshToken'>

const loginWithApple = async (
  options: LoginUserWithAppleOptions
): Promise<LoginWithAppleResponse> => {
  const response = await request({
    method: 'POST',
    url: url + '/login/apple',
    body: options,
    withCredentials: true,
  })

  if (response.status !== 200) {
    throw new ApiError('Invalid Credentials')
  }

  const { user } = response.body

  if (user) {
    setStore({
      user,
    })

    posthogIdentify(
      user._id,
      user.email || '',
      user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName || ''
    )
  }

  return response.body
}

type LoginWithMicrosoftResponse = Omit<LoginUserWithMicrosoftResponse, 'token' | 'refreshToken'>

const loginWithMicrosoft = async (
  options: LoginUserWithMicrosoftOptions
): Promise<LoginWithMicrosoftResponse> => {
  const response = await request({
    method: 'POST',
    url: url + '/login/microsoft',
    body: options,
    withCredentials: true,
  })

  if (response.status !== 200) {
    throw new ApiError('Invalid Credentials')
  }

  const { user } = response.body

  if (user) {
    setStore({
      user,
    })

    posthogIdentify(
      user._id,
      user.email || '',
      user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName || ''
    )
  }

  return response.body
}

type LoginWithTokenResponse = Omit<LoginUserWithTokenResponse, 'token' | 'refreshToken'>

const loginWithToken = async (): Promise<LoginWithTokenResponse> => {
  const response = await request({
    method: 'POST',
    url: url + '/login/token',
    withCredentials: true,
  })

  if (response.status !== 200) {
    throw new ApiError('Logged out due to inactivity')
  }

  const { user } = response.body

  if (user) {
    setStore({
      user,
    })
  }

  return response.body
}

type LoginUserByAdminResponse = Omit<LoginAdminUserResponse, 'token' | 'refreshToken'>

const loginAdminUser = async (
  options: Omit<LoginAdminUserOptions, 'token' | 'refreshToken'>
): Promise<LoginUserByAdminResponse> => {
  const response = await request({
    method: 'POST',
    url: url + '/login/admin',
    body: options,
    withCredentials: true,
  })

  if (response.status !== 200) {
    throw new ApiError('Invalid Credentials')
  }

  const { user } = response.body

  if (user) {
    setStore({
      user,
    })
  }

  return response.body
}

export {
  loginWithApple,
  loginWithEmail,
  loginWithEmail2FA,
  loginWithGoogle,
  loginWithMicrosoft,
  loginWithToken,
  loginWithPhone2FA,
  loginAdminUser,
}

type UpdateEmailOptions = Omit<UserUpdateEmailOptions, 'token' | 'refreshToken'>

export const updateEmail = async (
  options: UpdateEmailOptions
): Promise<UserUpdateEmailResponse> => {
  const response = await request({
    method: 'POST',
    url: url + '/account/update-email',
    body: options,
    withCredentials: true,
  })

  if (response.status !== 200) {
    throw new ApiError('Failed to update email')
  }

  return response.body
}

type UpdateEmailVerifyReponse = Omit<UserVerifyUpdateEmailResponse, 'token' | 'refreshToken'>

export const verifyUpdateEmail = async (
  options: UserVerifyUpdateEmailOptions
): Promise<UpdateEmailVerifyReponse> => {
  const response = await request({
    method: 'POST',
    url: url + '/account/verify-update-email',
    body: options,
    withCredentials: true,
  })

  if (response.status !== 200) {
    throw new ApiError('Failed to verify update email')
  }

  return response.body
}

export const generateOTC = async (): Promise<LoginUserGenerateOTCResponse> => {
  const response = await request({
    method: 'POST',
    url: url + '/login/generate-otc',
    withCredentials: true,
  })

  if (response.status !== 200) {
    throw new ApiError('Failed to generate OTP')
  }

  return response.body
}
