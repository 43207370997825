import { RRole, RoleSortableFields } from '@counsel-project/counsel-auth-api'
import RoleRow from './RoleRow'
import TableBase from './TableBase'
import useMediaQuery from '@mui/material/useMediaQuery'

export type RoleTableProps = {
  searchDisabled?: boolean
  rows: RRole[]
  page: number
  rowsPerPage: number
  rowsPerPageOptions: number[]
  total: number
  loaded: boolean
  sort: RoleSortableFields
  direction: 'asc' | 'desc'
  showEdit?: boolean
  showDelete?: boolean
  showPermission?: boolean
  checkedRoles: string[]
  onCheckRole: (id: string) => void
  onChangeSort?: (sort: RoleSortableFields, direction: 'asc' | 'desc') => void
  onChangePage?: (page: number) => void
  onChangeRowsPerPage?: (rowsPerPage: number) => void
  onClickRow?: (row: RRole) => void
  onSubmitSearch?: (search: string) => void
}

const RoleTable = ({
  searchDisabled,
  rows,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  total,
  loaded,
  sort,
  direction,
  showEdit,
  showDelete,
  showPermission,
  checkedRoles,
  onCheckRole,
  onChangeSort,
  onChangePage,
  onChangeRowsPerPage,
  onClickRow,
  onSubmitSearch,
}: RoleTableProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <TableBase
      searchDisabled={searchDisabled}
      columns={[
        { field: '_id', label: '', sortable: false, align: 'left' },
        { field: 'email', label: 'Email / Name', sortable: true, align: 'left' },
        {
          field: 'permission',
          label: 'Permission',
          sortable: true,
          align: 'right',
        },
      ]}
      rows={rows}
      renderRow={(row: RRole) => (
        <RoleRow
          key={row._id}
          isMobile={isMobile}
          data={row}
          showPermission={showPermission}
          checked={checkedRoles.includes(row._id)}
          onClick={onClickRow}
          onCheck={onCheckRole}
        />
      )}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      total={total}
      loaded={loaded}
      sort={sort}
      direction={direction}
      onChangeSort={onChangeSort}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSubmitSearch={onSubmitSearch}
    />
  )
}

export default RoleTable
