import { LanguageCode } from '@counsel-project/counsel-generation-api'
import { generateOTC } from './login'
import refreshToken from './refreshToken'

type OpenMacOSAppOption = {
  patientId?: string
  language: LanguageCode
  layout: string
}

export const openMacOSApp = async (options: OpenMacOSAppOption) => {
  const token = await refreshToken()
  if (!token) return

  const result = await generateOTC()

  const { otcToken } = result

  const deepLink = `cnai-audio-capture://open?token=${otcToken}&patientId=${
    options.patientId || ''
  }&language=${options.language}&layout=${options.layout}`

  window.location.href = deepLink
}

export const completeMacOSApp = async () => {
  const deepLink = `cnai-audio-capture://complete`

  window.location.href = deepLink
}

export const cancelMacOSApp = async () => {
  const deepLink = `cnai-audio-capture://cancel`

  window.location.href = deepLink
}
