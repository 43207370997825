import { RPatient } from '@counsel-project/counsel-transcribe-api'
import { AutocompleteProps } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect, useState } from 'react'
import PatientSelectorBase, { ehrs } from './PatientSelectorBase'
import handleError from '../util/handleError'
import { transcribeRequest } from '../util/api/transcribe-api'
import { usePatientNomenclature } from '../util'
import Button from '@mui/material/Button'
import { capitalize } from '@counsel-project/client-utils'
import PatientLinkDialog from './PatientLinkDialog'
import { RIntegrationPatient } from '@counsel-project/counsel-ehr-api'
import { ehrRequest } from '../util/api/ehr-api'
import toast from 'react-hot-toast'

export type PatientSelectorProps = {
  id?: string
  placeholder?: string
  hiddenOptions?: string[]
  value: RPatient | string | null
  onChange: (value: RPatient | null) => void
} & Omit<
  AutocompleteProps<RPatient, false, false, true>,
  'renderInput' | 'renderOption' | 'onChange' | 'value' | 'options'
>

const PatientSelector = (
  {
    id,
    placeholder = 'Search or Create New...',
    hiddenOptions,
    value,
    onChange,
    ...props
  }: PatientSelectorProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const [inputValue, setInputValue] = useState('')
  const [shownOptions, setShownOptions] = useState<RPatient[]>([])

  const [similarNamedPatient, setSimilarNamedPatient] = useState<RPatient | null>(null)
  const [integrationPatient, setIntegrationPatient] = useState<RIntegrationPatient | null>(null)
  const [linkDialogOpen, setLinkDialogOpen] = useState(false)

  const populateSimilarNamedPatients = useCallback(async () => {
    try {
      if (
        !value ||
        typeof value === 'string' ||
        !value.source ||
        value._id ||
        !value.integrationPatientId
      ) {
        setSimilarNamedPatient(null)
        return
      }

      // Only trigger if value is a new patient with an ehr source

      // EHR label is in the format of "John Doe (DTC_1234567890)"
      // We need to remove the last part in the parentheses
      const searchLabel = value.label.split('(')[0]?.trim()

      console.log('searchLabel', searchLabel)

      if (!searchLabel) return

      const { results } = await transcribeRequest.patients.list.all({
        token: '',
        search: {
          and: [{ label: searchLabel }],
        },
        limit: 1,
      })

      if (results.length === 0) return

      const { result } = await ehrRequest.integrations.patients.get({
        token: '',
        patientId: value.integrationPatientId,
      })

      setIntegrationPatient(result)
      setSimilarNamedPatient(results[0])
    } catch (err) {
      handleError(err)
    }
  }, [value])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateSimilarNamedPatients()
    }, 10)

    return () => clearTimeout(timeout)
  }, [populateSimilarNamedPatients])

  const patientNomenclature = usePatientNomenclature()
  const capitalPatientNomenclature = capitalize(patientNomenclature)

  const filteredShownOptions = shownOptions.filter(
    (option) => !hiddenOptions?.includes(option.label) && !option.source
  )

  return (
    <>
      <PatientSelectorBase
        id={id}
        onChangeInputValue={setInputValue}
        onChangeShownOptions={setShownOptions}
        onChange={onChange}
        value={value}
        hiddenOptions={hiddenOptions}
        placeholder={placeholder}
        {...props}
        ref={ref}
      />
      {typeof value !== 'string' && !value?._id && value && !value.source && (
        <Fade in>
          <Box>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }} fontStyle="italic">
              Creating a new {patientNomenclature} "{inputValue}".{' '}
              {filteredShownOptions.length !== 0 ? 'Did you mean...' : ''}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                mt: 1,
                overflowX: 'auto',
              }}
            >
              {filteredShownOptions.map((option) => (
                <Chip
                  key={option._id}
                  label={option.label}
                  onClick={() => {
                    onChange(option)
                  }}
                  sx={{ mr: 1, mb: 1, cursor: 'pointer', fontWeight: 500, fontSize: '0.875rem' }}
                  size="medium"
                />
              ))}
            </Box>
          </Box>
        </Fade>
      )}
      {typeof value !== 'string' &&
        !value?._id &&
        !!value &&
        !!value.source &&
        !!similarNamedPatient && (
          <Fade in>
            <Box>
              <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                It looks like the {patientNomenclature} "<strong>{value.label}</strong>" has been
                used with Clinical Notes AI before, but under a different name. It looks like the
                MRN has not been updated since you last used the platform.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 1,
                  overflowX: 'auto',
                }}
              >
                <Button
                  onClick={() => {
                    onChange(similarNamedPatient)
                  }}
                  sx={{ mr: 1, mb: 1, cursor: 'pointer', fontWeight: 500, fontSize: '0.875rem' }}
                  size="small"
                  color="primary"
                >
                  Switch to <strong style={{ marginLeft: 4 }}>{similarNamedPatient.label}</strong>
                </Button>
                <Button
                  onClick={() => setLinkDialogOpen(true)}
                  sx={{ mr: 1, mb: 1, cursor: 'pointer', fontWeight: 500, fontSize: '0.875rem' }}
                  size="small"
                  color="primary"
                  variant="contained"
                >
                  Link These Two {capitalPatientNomenclature}s
                </Button>
              </Box>
            </Box>
          </Fade>
        )}
      {typeof value !== 'string' &&
        !value?._id &&
        !!value &&
        !!value.source &&
        !!similarNamedPatient && (
          <PatientLinkDialog
            open={linkDialogOpen}
            onClose={() => setLinkDialogOpen(false)}
            initialPatient={similarNamedPatient}
            initialIntegrationPatient={integrationPatient}
            onSave={(patient, integrationPatient) => {
              onChange(patient)
              setLinkDialogOpen(false)
              toast.success('Successfully linked')
            }}
          />
        )}
    </>
  )
}

export default React.forwardRef(PatientSelector)
