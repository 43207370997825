import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTitle } from 'react-use'
import OverlayLoader from '../OverlayLoader'
import { cancelMacOSApp, completeMacOSApp } from '../../../util/auth/macOSApp'
import { useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'
import ExpandingButton from '../../../components/ExpandingButton'

const BuilderTranscribeAppPage = () => {
  useTitle('Clinical Notes AI - Transcribe')

  const navigate = useNavigate()

  const handleCompleteCapture = () => {
    completeMacOSApp()
    navigate('/close-window')
  }

  const handleCancelCapture = () => {
    cancelMacOSApp()
    navigate('/close-window')
  }

  return (
    <OverlayLoader loaded>
      <Grid container alignItems="center" justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={500} textAlign="center" sx={{ pb: 2 }}>
            Please continue to the Clinical Notes AI Audio Capture app to complete your session
          </Typography>
        </Grid>
        <Grid item xs={8} sm={6} md={4} lg={3} container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleCompleteCapture} fullWidth>
              Complete Capture
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="secondary" onClick={handleCancelCapture} fullWidth>
              Cancel Capture
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ExpandingButton text="Not capturing audio?">
              <Typography variant="body1" sx={{ p: 2 }}>
                Please ensure that the app is running and that you have granted permission to use
                the microphone and share desktop audio. Go back{' '}
                <Link href="/builder/transcribe-new">here</Link> to try again.
              </Typography>
            </ExpandingButton>
          </Grid>
        </Grid>
      </Grid>
    </OverlayLoader>
  )
}

export default BuilderTranscribeAppPage
