import GettingStartedExtension from './getting-started-extension'
import ExtTemplateModification from './ext-template-modification'
import WebTemplateModification from './template-modification'
import ExtSimplePractice from './ext-simplepractice'
import ExtZanda from './ext-zanda'
import ExtTherapyNotes from './ext-therapynotes'
import ExtPracticeQ from './ext-practiceq'
import Walkthrough from './walkthrough'

export const articles = [
  Walkthrough,
  WebTemplateModification,
  GettingStartedExtension,
  ExtTemplateModification,
  ExtSimplePractice,
  ExtZanda,
  ExtTherapyNotes,
  ExtPracticeQ,
]
