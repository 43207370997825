import Paper, { PaperProps } from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useState } from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { styled } from '@mui/material/styles'

type RotatingIconProps = {
  rotating: boolean
}

const RotatingIcon = styled(ChevronRightIcon, {
  shouldForwardProp: (prop) => prop !== 'rotating',
})<RotatingIconProps>(({ rotating }) => ({
  transition: 'transform 0.3s',
  transform: rotating ? 'rotate(90deg)' : 'rotate(0deg)',
}))

type DropdownSectionProps = {
  title: string
  subtitle: string
  children: React.ReactNode
  defaultOpen?: boolean
} & PaperProps

const DropdownSection = ({
  title,
  subtitle,
  children,
  defaultOpen = false,
  ...props
}: DropdownSectionProps) => {
  const [open, setOpen] = useState(defaultOpen)

  return (
    <Paper {...props} sx={{ p: 2, ...props.sx }} elevation={0}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs>
          <Typography variant="h5" sx={{ mb: 1 }}>
            {title}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {subtitle}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton color="primary" size="large" sx={{ p: 2 }} onClick={() => setOpen(!open)}>
            <RotatingIcon rotating={open} />
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={open}>
        <Box sx={{ mt: 1 }}>{children}</Box>
      </Collapse>
    </Paper>
  )
}

export default DropdownSection
