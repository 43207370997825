import { useState } from 'react'
import PageContainer from '../../components/layout/PageContainer'
import Button from '@mui/material/Button'
import AudioSetupDialog, { DeviceOptionsSelected } from './AudioSetupDialog'

const TestAppPage = () => {
  const [audioSetupDialogOpen, setAudioSetupDialogOpen] = useState(false)

  const handleOptionsSelected = (options?: DeviceOptionsSelected) => {
    console.log('options', options)
  }

  return (
    <PageContainer>
      <Button onClick={() => setAudioSetupDialogOpen(true)}>Open Audio Setup Dialog</Button>
      <AudioSetupDialog
        open={audioSetupDialogOpen}
        onClose={() => setAudioSetupDialogOpen(false)}
        dictation={false}
        onOptionsSelected={handleOptionsSelected}
      />
    </PageContainer>
  )
}

export default TestAppPage
