// Markdown parser to output to editorjs blocks

import inlineParser from './inlineParser'

const ensureTableHasSameNumberOfColumns = (block: {
  type: 'table'
  data: {
    content: string[][]
    checked: (boolean | null)[][]
  }
}) => {
  const maxColumns = Math.max(...block.data.content.map((row) => row.length))
  block.data.content = block.data.content.map((row) => {
    while (row.length < maxColumns) {
      row.push('')
    }
    return row
  })
  block.data.checked = block.data.checked.map((row) => {
    while (row.length < maxColumns) {
      row.push(null)
    }
    return row
  })
}

const blockEndsWithTicks = (block: EditorJS.OutputData['blocks'][0]) => {
  return block?.type === 'exportBox' || block.type === 'aiBox' || block.type === 'aiExportBox'
}

const markdownToBlocks = (markdown: string, template?: boolean): EditorJS.OutputData['blocks'] => {
  const lines = markdown.split('\n').map((l) => l.trim())
  const blocks: EditorJS.OutputData['blocks'] = []

  let currentBlock: EditorJS.OutputData['blocks'][0] | null = null

  const pushBlockIfExists = () => {
    if (currentBlock) {
      blocks.push(currentBlock)
    }
  }

  for (const line of lines) {
    if (line === '' && currentBlock && blockEndsWithTicks(currentBlock)) {
      currentBlock.data.content += '\n'
    } else if (line.startsWith('```ai-exportable')) {
      pushBlockIfExists()
      currentBlock = {
        type: 'aiExportBox',
        data: {
          content: '',
        },
      }
    } else if (line.startsWith('```ai')) {
      pushBlockIfExists()
      currentBlock = {
        type: 'aiBox',
        data: {
          content: '',
        },
      }
    } else if (line.startsWith('```exportable')) {
      pushBlockIfExists()
      currentBlock = {
        type: 'exportBox',
        data: {
          content: '',
        },
      }
    } else if (line.startsWith('```')) {
      if (currentBlock && blockEndsWithTicks(currentBlock)) {
        blocks.push(currentBlock)
        currentBlock = null
      }
    } else if (currentBlock && blockEndsWithTicks(currentBlock)) {
      currentBlock.data.content += line + '\n'
    } else if (line === '---') {
      pushBlockIfExists()
      currentBlock = {
        type: 'pageBreak',
        data: {},
      }
    } else if (line.startsWith('#')) {
      pushBlockIfExists()
      currentBlock = {
        type: 'header',
        data: {
          level: line.split(' ')[0].length,
          text: inlineParser(line.slice(line.indexOf(' ') + 1), template),
        },
      }
    } else if (line.startsWith('- [ ] ') || line.startsWith('- [x] ')) {
      if (currentBlock?.type !== 'checklist') {
        pushBlockIfExists()
        currentBlock = {
          type: 'checklist',
          data: {
            items: [],
          },
        }
      }
      currentBlock.data.items.push({
        text: inlineParser(line.slice(6), template),
        checked: line.startsWith('- [x]'),
      })
    } else if (line.startsWith('|')) {
      if (!currentBlock || currentBlock.type !== 'table') {
        pushBlockIfExists()
        currentBlock = {
          type: 'table',
          data: {
            withHeadings: true,
            content: [],
            checked: [],
          },
        }
      }

      const splitLine = line
        .slice(1, -1)
        .split('|')
        .map((l) => l.trim())

      if (
        splitLine
          .filter((t) => t.length > 0)
          .every((t) => t.split('-').join('').split(':').join('').length === 0) &&
        line.includes('---')
      ) {
        continue
      }
      const checkboxCells = splitLine.map((cell) =>
        cell.startsWith('- [x]')
          ? true
          : cell.startsWith('- [ ]') || cell.startsWith('- []')
            ? false
            : null
      )

      if (!currentBlock.data.checked) {
        currentBlock.data.checked = currentBlock.data.content.map(() =>
          [...new Array(checkboxCells.length)].map(() => null)
        )
      }

      currentBlock.data.checked.push(checkboxCells)

      currentBlock.data.content.push(
        splitLine.map((cell) =>
          inlineParser(cell.split('- [ ]').join('').split('- [x]').join(''), template).trim()
        )
      )
    } else {
      pushBlockIfExists()
      currentBlock = {
        type: 'paragraph',
        data: {
          text: inlineParser(line, template),
        },
      }
    }
  }

  pushBlockIfExists()

  // Ensure all table rows have the same number of columns
  blocks
    .filter((block) => block.type === 'table')
    .forEach((block) => {
      ensureTableHasSameNumberOfColumns(block as any)
    })

  return blocks
}

export default markdownToBlocks
