import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { RDirectory } from '@counsel-project/counsel-auth-api'
import { DirectorySettings } from '@counsel-project/counsel-auth-api/dist/common/database/Directory'
import checkToken from '../../util/auth/checkToken'
import { authRequest } from '../../util/api/auth-api'
import handleError from '../../util/handleError'
import Paper from '@mui/material/Paper'
import { usePatientNomenclature } from '../../util'
import SelectableTab from '../../components/SelectableTab'
import Button from '@mui/material/Button'
import ClearDataDialog from './ClearDataDialog'
import TextAccordion from '../../components/TextAccordion'
import Link from '@mui/material/Link'

type OrganizationSettingsProps = {
  disabled?: boolean
  directory: RDirectory
  onChangeDirectory: (directory: RDirectory) => void
}

const OrganizationSettings = ({
  disabled,
  directory,
  onChangeDirectory,
}: OrganizationSettingsProps) => {
  const [loading, setLoading] = useState(false)
  const [settings, setSettings] = useState<DirectorySettings>({
    shareData: false,
    supervisorsCanEdit: false,
    adminsCanEdit: false,
    managersCanEdit: false,
    noteRetentionMode: 'on-saved',
    sessionRetentionMode: 'zdr',
    dataCollectionOptOut: false,
    onlyShowDirectoryLayouts: false,
    forceLiveSessionPatientSelection: false,
  })
  const [clearOpen, setClearOpen] = useState(false)

  useEffect(() => {
    const { settings: newSettings } = directory
    if (!newSettings) return
    setSettings({
      shareData: newSettings.shareData,
      supervisorsCanEdit: newSettings.supervisorsCanEdit,
      adminsCanEdit: newSettings.adminsCanEdit,
      managersCanEdit: newSettings.managersCanEdit,
      noteRetentionMode: newSettings.noteRetentionMode,
      sessionRetentionMode: newSettings.sessionRetentionMode,
      dataCollectionOptOut: newSettings.dataCollectionOptOut,
      onlyShowDirectoryLayouts: newSettings.onlyShowDirectoryLayouts,
      forceLiveSessionPatientSelection: newSettings.forceLiveSessionPatientSelection,
    })
  }, [directory])

  const handleSave = useCallback(
    async (newSettings: DirectorySettings) => {
      try {
        setLoading(true)

        await checkToken()

        const { result } = await authRequest.user.directories.settings.update({
          token: '',
          directoryId: directory._id,
          ...newSettings,
        })

        onChangeDirectory({ ...directory, settings: result })
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    },
    [directory, onChangeDirectory]
  )

  const handleChange = useCallback(
    (key: keyof DirectorySettings) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSettings = { ...settings, [key]: e.target.checked }
      setSettings(newSettings)
      handleSave(newSettings)
    },
    [settings, handleSave]
  )

  const handleChangeReverse = useCallback(
    (key: keyof DirectorySettings) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSettings = { ...settings, [key]: !e.target.checked }
      setSettings(newSettings)
      handleSave(newSettings)
    },
    [settings, handleSave]
  )

  const patientNomenclature = usePatientNomenclature()

  const handleOpenDeidentificationDocument = (e: React.MouseEvent) => {
    e.preventDefault()
    window.open('/deidentification-process.pdf', '_blank')
  }

  return (
    <Paper elevation={0} sx={{ mt: 2 }}>
      <Grid container spacing={2} px={2} pb={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h6" fontWeight={500} fontSize={18}>
              Settings
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Configure the settings for this organization.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={settings.shareData}
                onChange={handleChange('shareData')}
                disabled={disabled || loading}
              />
            }
            label="Share Organization Documentation"
          />
          <Typography variant="body1" color="text.secondary">
            Allow all organization members to view and edit each other's {patientNomenclature}s &
            documentation.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={settings.supervisorsCanEdit}
                onChange={handleChange('supervisorsCanEdit')}
                disabled={disabled || loading}
              />
            }
            label="Supervisors Can Update Sub-User Data"
          />
          <Typography variant="body1" color="text.secondary">
            Allow supervisors to edit {patientNomenclature}s & documentation of their sub-users.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={settings.adminsCanEdit}
                onChange={handleChange('adminsCanEdit')}
                disabled={disabled || loading}
              />
            }
            label="Admins Can Update Sub-User Data"
          />
          <Typography variant="body1" color="text.secondary">
            Allow administrators to edit {patientNomenclature}s & documentation of their sub-users.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={settings.managersCanEdit}
                onChange={handleChange('managersCanEdit')}
                disabled={disabled || loading}
              />
            }
            label="Managers Can Update Sub-User Data"
          />
          <Typography variant="body1" color="text.secondary">
            Allow managers to edit {patientNomenclature}s & documentation of their sub-users.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={settings.onlyShowDirectoryLayouts}
                onChange={handleChange('onlyShowDirectoryLayouts')}
                disabled={disabled || loading}
              />
            }
            label="Only Show Organization Templates"
          />
          <Typography variant="body1" color="text.secondary">
            Only display organization templates in the documentation screen. Hides all preset
            templates.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={settings.forceLiveSessionPatientSelection}
                onChange={handleChange('forceLiveSessionPatientSelection')}
                disabled={disabled || loading}
              />
            }
            label="Force Patient Selection for Live Sessions"
          />
          <Typography variant="body1" color="text.secondary">
            Force clinicians to select a patient before starting a live session.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h6" fontWeight={500} fontSize={18}>
              Transcript/Session Retention
            </Typography>
            <Typography variant="body1">
              Configure how transcripts (Live Sessions & Dictations) are retained.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectableTab
            small
            active={settings.sessionRetentionMode === 'zdr' || !settings.sessionRetentionMode}
            onClick={() => handleSave({ ...settings, sessionRetentionMode: 'zdr' })}
          >
            Zero Retention
          </SelectableTab>
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectableTab
            small
            active={settings.sessionRetentionMode === 'on-saved'}
            onClick={() => handleSave({ ...settings, sessionRetentionMode: 'on-saved' })}
          >
            Retain When Saved
          </SelectableTab>
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectableTab
            small
            active={settings.sessionRetentionMode === 'always'}
            onClick={() => handleSave({ ...settings, sessionRetentionMode: 'always' })}
          >
            Always Retain
          </SelectableTab>
        </Grid>
        <Grid item xs={12}>
          {!settings.sessionRetentionMode || settings.sessionRetentionMode === 'zdr' ? (
            <Box>
              <Typography variant="body1" color="text.secondary">
                New transcripts will only ever be retained for 14 days after creation.
              </Typography>
            </Box>
          ) : settings.sessionRetentionMode === 'on-saved' ? (
            <Box>
              <Typography variant="body1" color="text.secondary">
                New transcripts will be retained when an organization member saves them
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="body1" color="text.secondary">
                New transcripts will always be retained.
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h6" fontWeight={500} fontSize={18}>
              Documentation Retention
            </Typography>
            <Typography variant="body1">
              Configure how documentation (Notes & Documents) are retained.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectableTab
            small
            active={settings.noteRetentionMode === 'zdr'}
            onClick={() => handleSave({ ...settings, noteRetentionMode: 'zdr' })}
          >
            Zero Retention
          </SelectableTab>
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectableTab
            small
            active={settings.noteRetentionMode === 'on-saved' || !settings.noteRetentionMode}
            onClick={() => handleSave({ ...settings, noteRetentionMode: 'on-saved' })}
          >
            Retain When Saved
          </SelectableTab>
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectableTab
            small
            active={settings.noteRetentionMode === 'always'}
            onClick={() => handleSave({ ...settings, noteRetentionMode: 'always' })}
          >
            Always Retain
          </SelectableTab>
        </Grid>
        <Grid item xs={12}>
          {settings.noteRetentionMode === 'zdr' ? (
            <Box>
              <Typography variant="body1" color="text.secondary">
                New documents will only ever be retained for 14 days after creation.
              </Typography>
            </Box>
          ) : !settings.noteRetentionMode || settings.noteRetentionMode === 'on-saved' ? (
            <Box>
              <Typography variant="body1" color="text.secondary">
                New documents will be retained when an organization member saves them
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="body1" color="text.secondary">
                New documents will always be retained.
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextAccordion id="data-collection-opt-out" title="More Options">
            <FormControlLabel
              control={
                <Checkbox
                  checked={!settings.dataCollectionOptOut}
                  onChange={handleChangeReverse('dataCollectionOptOut')}
                  disabled={disabled || loading}
                />
              }
              label="Opt-in to De-Identified data for product improvement"
            />
            <Typography variant="body1" color="text.secondary">
              Uncheck this box to opt out of using De-Identified data to improve the quality of our
              systems. We do not sell your data. View our Privacy Policy{' '}
              <Link
                aria-label="Privacy Policy"
                href="https://www.clinicalnotes.ai/privacy-policy.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                HERE
              </Link>{' '}
              and De-Identification process here per HIPAA{' '}
              <Link
                aria-label="De-Identification Process"
                href="/deidentification-process.pdf"
                onClick={handleOpenDeidentificationDocument}
              >
                HERE
              </Link>
              .
            </Typography>
          </TextAccordion>
        </Grid>
        <Grid item>
          <Button color="error" onClick={() => setClearOpen(true)}>
            Clear Data
          </Button>
        </Grid>
      </Grid>
      <ClearDataDialog
        open={clearOpen}
        onClose={() => setClearOpen(false)}
        directoryId={directory._id}
      />
    </Paper>
  )
}

export default OrganizationSettings
