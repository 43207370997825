import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import ClosableDialog from '../../../components/ClosableDialog'
import { capitalize, wait } from '@counsel-project/client-utils'
import { useCallback, useEffect, useRef, useState } from 'react'
import handleError from '../../../util/handleError'
import { ehrRequest } from '../../../util/api/ehr-api'
import checkToken from '../../../util/auth/checkToken'
import Skeleton from '@mui/material/Skeleton'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import ImportIcon from '@mui/icons-material/DownloadRounded'
import { RIntegrationUser } from '@counsel-project/counsel-ehr-api/dist/common/database/RIntegrationUser'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Pagination from '@mui/material/Pagination'
import Grid from '@mui/material/Grid'
import { authRequest } from '../../../util/api/auth-api'
import { RRole } from '@counsel-project/counsel-auth-api'
import OverlayLoader from '../../builder/OverlayLoader'

type ImportUsersDialogProps = {
  open: boolean
  onClose: () => void
  integrationId: string
  integrationType: string
  directoryId: string
  seatsLeft: number
}

const ImportUsersDialog = ({
  open,
  onClose,
  integrationId,
  integrationType,
  directoryId,
  seatsLeft,
}: ImportUsersDialogProps) => {
  const [userCount, setUserCount] = useState<number | null>(null)
  const [shownUsers, setShownUsers] = useState<RIntegrationUser[]>([])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [importAsNonLicensed, setImportAsNonLicensed] = useState(true)
  const [tableView, setTableView] = useState(false)
  const [selectedEmails, setSelectedEmails] = useState<string[]>([])
  const [licensedEmails, setLicensedEmails] = useState<string[]>([])
  const [existingRoleEmails, setExistingRoleEmails] = useState<string[]>([])
  const [importLoading, setImportLoading] = useState(false)
  const [importState, setImportState] = useState(0)
  const [importComplete, setImportComplete] = useState(false)
  const importCanceled = useRef(false)

  const limit = 10

  useEffect(() => {
    if (open) {
      setTableView(false)
      setSelectedEmails([])
      setPage(1)
      setSearch('')
      setImportComplete(false)
    }
  }, [open])

  const populateUserCount = useCallback(async () => {
    try {
      if (!integrationId) return

      await checkToken()

      const { total, results } = await ehrRequest.integrations.users.listByIntegration({
        token: '',
        integrationId,
        limit,
        offset: (page - 1) * limit,
        sort: 'name',
        direction: 'desc',
        search: search
          ? {
              or: [
                {
                  email: search,
                  $fuzzy: true,
                },
                {
                  name: search,
                  $fuzzy: true,
                },
              ],
            }
          : undefined,
      })

      setShownUsers(results)
      setUserCount(total)
    } catch (err) {
      handleError(err)
    }
  }, [integrationId, page, search])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateUserCount()
    }, 10)

    return () => clearTimeout(timeout)
  }, [populateUserCount])

  const handleCompleteImport = useCallback(async () => {
    try {
      if (!integrationId) return

      setImportLoading(true)

      importCanceled.current = false

      await checkToken()

      const newRoles: RRole[] = []

      setImportState(0)

      for (const email of selectedEmails) {
        if (importCanceled.current) {
          return
        }

        if (existingRoleEmails.includes(email)) {
          continue
        }

        try {
          const { result: newRole } = await authRequest.user.directories.roles.create({
            token: '',
            directoryId,
            email,
            permission: 'counselor',
            ignoreLicense: !licensedEmails.includes(email),
          })

          newRoles.push(newRole)
          setImportState((prev) => prev + 1)
        } catch (err) {
          await wait(100)
          console.error(err)
          try {
            const { result: newRole } = await authRequest.user.directories.roles.create({
              token: '',
              directoryId,
              email,
              permission: 'counselor',
              ignoreLicense: !licensedEmails.includes(email),
            })

            newRoles.push(newRole)
            setImportState((prev) => prev + 1)
          } catch (err) {
            handleError(err)
          }
        }
      }

      setImportComplete(true)
    } catch (err) {
      handleError(err)
    } finally {
      setImportLoading(false)
    }
  }, [integrationId, selectedEmails, licensedEmails, existingRoleEmails, directoryId])

  const handleClickImport = () => {
    if (!tableView) {
      handleToggleAllUsers()
      setTableView(true)
    } else {
      handleCompleteImport()
    }
  }

  const handleToggleEmail = (email: string) => {
    if (existingRoleEmails.includes(email)) {
      return
    }

    setSelectedEmails((prev) => {
      if (prev.includes(email)) {
        return prev.filter((e) => e !== email)
      }
      return [...prev, email]
    })
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handleIncludeEveryUser = useCallback(async () => {
    try {
      if (!integrationId) return

      setLoading(true)

      await checkToken()

      const perBatch = 50
      const newUsers: RIntegrationUser[] = []
      const existingRoles: string[] = []

      const max = 999999

      for (let i = 0; i < max; i += perBatch) {
        const { results, total } = await ehrRequest.integrations.users.listByIntegration({
          token: '',
          integrationId,
          limit: perBatch,
          offset: i,
          sort: 'name',
          direction: 'desc',
        })

        const { results: roles } = await authRequest.user.directories.roles.list({
          token: '',
          directoryId,
          limit: 100,
          search: {
            or: [
              ...results.map((user) => ({
                email: user.email,
              })),
            ],
          },
        })

        if (roles.length > 0) {
          existingRoles.push(...roles.map((role) => role.email))
        }

        if (results.length === 0) break

        if (total === 0) break

        newUsers.push(...results.filter((user) => !existingRoles.includes(user.email)))

        if (total < i + perBatch) {
          break
        }
      }

      setSelectedEmails(newUsers.map((user) => user.email))
      setExistingRoleEmails(existingRoles)
      if (importAsNonLicensed || seatsLeft < newUsers.length) {
        setLicensedEmails([])
      } else {
        setLicensedEmails(newUsers.map((user) => user.email))
      }
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [integrationId, importAsNonLicensed, seatsLeft, directoryId])

  const handleToggleAllUsers = () => {
    if (loading) return

    if (selectedEmails.length === (userCount || 0) - existingRoleEmails.length) {
      setSelectedEmails([])
    } else {
      handleIncludeEveryUser()
    }
  }

  const handleToggleLicensedEmail = (event: React.MouseEvent<HTMLButtonElement>, email: string) => {
    event.stopPropagation()

    setLicensedEmails((prev) => {
      if (prev.includes(email)) {
        return prev.filter((e) => e !== email)
      }
      if (seatsLeft - licensedEmails.length <= 0) {
        return prev
      }
      if (existingRoleEmails.includes(email)) {
        return prev
      }
      return [...prev, email]
    })
  }

  if (importLoading) {
    return (
      <OverlayLoader>
        <Grid container alignItems="center" justifyContent="center" spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }} textAlign="center">
              Importing clinicians... This may take a few seconds.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary" textAlign="center">
              {importState} clinicians imported
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={() => (importCanceled.current = true)}>Cancel</Button>
          </Grid>
        </Grid>
      </OverlayLoader>
    )
  }

  return (
    <ClosableDialog
      open={open}
      onClose={onClose}
      titleText={`Import Clinicians from ${capitalize(integrationType)}`}
    >
      {!tableView && !importComplete ? (
        <DialogContent>
          {userCount === null ? (
            <Skeleton variant="text" height={20} />
          ) : (
            <Typography variant="body1">
              Import {userCount} clinicians from your {capitalize(integrationType)} integration to
              your organization.
            </Typography>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={importAsNonLicensed}
                onChange={() => setImportAsNonLicensed(!importAsNonLicensed)}
              />
            }
            label="Import as non-licensed clinicians"
          />
          <Typography variant="body1" color="text.secondary" gutterBottom>
            If you select this option, the clinicians that are imported will be not use the
            organization license. This can be changed at any time per-clinician.
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Otherwise, clinicians that are imported will use the organization license. If there are
            not enough license slots available, the system will ask you who you want the licenses to
            apply to.
          </Typography>
        </DialogContent>
      ) : !importComplete ? (
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      disabled={loading}
                      sx={{ p: 0 }}
                      onClick={handleToggleAllUsers}
                      checked={
                        selectedEmails.length === (userCount || 0) - existingRoleEmails.length
                      }
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Use License?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shownUsers.map((user) => (
                  <TableRow
                    key={user.identifier}
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: existingRoleEmails.includes(user.email)
                        ? 'rgba(0, 0, 0, 0.1)'
                        : 'transparent',
                    }}
                    onClick={() => handleToggleEmail(user.email)}
                  >
                    <TableCell>
                      <Checkbox
                        disabled={loading}
                        sx={{ p: 0 }}
                        checked={selectedEmails.includes(user.email)}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        textOverflow="ellipsis"
                        sx={{ maxWidth: 100 }}
                        noWrap
                      >
                        {user.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        textOverflow="ellipsis"
                        sx={{ maxWidth: 240 }}
                        noWrap
                      >
                        {user.email}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="secondary"
                        disabled={loading}
                        sx={{ p: 0 }}
                        checked={licensedEmails.includes(user.email)}
                        onClick={(e) => handleToggleLicensedEmail(e, user.email)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {userCount !== null && (
            <Pagination
              sx={{ mt: 1 }}
              count={Math.ceil(userCount / limit)}
              page={page}
              defaultPage={1}
              onChange={handleChangePage}
            />
          )}
          {seatsLeft - licensedEmails.length > 0 ? (
            <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
              {seatsLeft - licensedEmails.length} license slots left
            </Typography>
          ) : (
            <Typography variant="body1" color="error" sx={{ mt: 1 }}>
              You have no license slots left.
            </Typography>
          )}
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography variant="body1" fontWeight="bold" gutterBottom>
            Import Complete
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {importState} clinicians imported
          </Typography>
        </DialogContent>
      )}
      {!importComplete ? (
        <DialogActions>
          {selectedEmails.length > 0 && (
            <Typography variant="body1" color="text.secondary">
              {selectedEmails.length} clinician{selectedEmails.length > 1 ? 's' : ''} selected
            </Typography>
          )}
          <Button onClick={onClose}>Cancel</Button>

          <Button
            color="secondary"
            startIcon={tableView ? <ImportIcon /> : undefined}
            onClick={handleClickImport}
            disabled={loading || (seatsLeft - licensedEmails.length <= 0 && tableView)}
          >
            {tableView ? 'Complete Import' : 'Next'}
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      )}
    </ClosableDialog>
  )
}

export default ImportUsersDialog
