import { capitalize } from '@counsel-project/client-utils'
import { RIntegration } from '@counsel-project/counsel-ehr-api'
import RefreshIcon from '@mui/icons-material/RefreshRounded'
import SettingsIcon from '@mui/icons-material/SettingsRounded'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import React, { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import ClosableDialog from '../../../components/ClosableDialog'
import SelectableTab from '../../../components/SelectableTab'
import { usePatientNomenclature } from '../../../util'
import { ehrRequest } from '../../../util/api/ehr-api'
import checkToken from '../../../util/auth/checkToken'
import handleError from '../../../util/handleError'
import ImportUsersDialog from './ImportUsersDialog'
import IntegrationPatientsDisplay from './IntegrationPatientsDisplay'
import IntegrationSettingsDisplay from './IntegrationSettingsDisplay'
import IntegrationTemplatesDisplay from './IntegrationTemplatesDisplay'
import IntegrationUsersDisplay from './IntegrationUsersDisplay'
import ImportIcon from '@mui/icons-material/DownloadRounded'

type IntegrationDialogProps = {
  open: boolean
  onClose: () => void
  integration: RIntegration
  onDelete: () => void
  onUpdateIntegration: (integration: RIntegration) => void
  onRefreshUsers: () => void
  seatsLeft: number
}

const IntegrationDialog: React.FC<IntegrationDialogProps> = ({
  open,
  onClose,
  integration,
  onDelete,
  onUpdateIntegration,
  onRefreshUsers,
  seatsLeft,
}) => {
  const [loading, setLoading] = useState(false)

  const [tab, setTab] = useState<'patients' | 'templates' | 'users' | 'settings'>('patients')

  const [syncUsersOpen, setSyncUsersOpen] = useState(false)

  const handleSync = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const { result } = await ehrRequest.integrations.sync({
        token: '',
        integrationId: integration._id,
      })

      onUpdateIntegration(result)
      onClose()
      toast.success('Integration syncing...')
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [integration, onUpdateIntegration, onClose])

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      await ehrRequest.integrations.delete({
        token: '',
        integrationId: integration._id,
      })

      toast.success('Integration removed')
      onDelete()
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [onDelete, integration])

  const patientNomenclature = usePatientNomenclature()

  const changeTabHandler = (tab: 'patients' | 'templates' | 'users' | 'settings') => () => {
    setTab(tab)
  }

  const handleClickImportUsers = () => {
    setSyncUsersOpen(true)
  }

  const handleCloseImportUsers = () => {
    setSyncUsersOpen(false)
    onRefreshUsers()
  }

  return (
    <>
      <ImportUsersDialog
        open={syncUsersOpen}
        onClose={handleCloseImportUsers}
        integrationId={integration._id}
        integrationType={integration.type}
        directoryId={integration.directoryId}
        seatsLeft={seatsLeft}
      />
      <ClosableDialog
        open={open && !syncUsersOpen}
        onClose={onClose}
        titleText={capitalize(integration.type)}
        headerComponent={
          <IconButton
            onClick={changeTabHandler('settings')}
            sx={{
              mr: 1,
              ...(tab === 'settings' && {
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
              }),
            }}
            color="primary"
          >
            <SettingsIcon />
          </IconButton>
        }
      >
        <DialogContent sx={{ minHeight: 670 }}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs>
              <SelectableTab
                active={tab === 'patients'}
                onClick={changeTabHandler('patients')}
                small
              >
                {capitalize(patientNomenclature)}s
              </SelectableTab>
            </Grid>
            <Grid item xs>
              <SelectableTab
                active={tab === 'templates'}
                onClick={changeTabHandler('templates')}
                small
              >
                Templates
              </SelectableTab>
            </Grid>
            <Grid item xs>
              <SelectableTab active={tab === 'users'} onClick={changeTabHandler('users')} small>
                Clinicians
              </SelectableTab>
            </Grid>
          </Grid>
          {tab === 'patients' && <IntegrationPatientsDisplay integrationId={integration._id} />}
          {tab === 'templates' && <IntegrationTemplatesDisplay integrationId={integration._id} />}
          {tab === 'users' && <IntegrationUsersDisplay integrationId={integration._id} />}
          {tab === 'settings' && (
            <IntegrationSettingsDisplay
              integration={integration}
              onChange={onUpdateIntegration}
              onSync={handleSync}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            {tab === 'users' && (
              <Grid item xs={12} sm>
                <Button
                  onClick={handleClickImportUsers}
                  color="primary"
                  disabled={loading}
                  startIcon={<ImportIcon />}
                  fullWidth
                >
                  Import Clinicians
                </Button>
              </Grid>
            )}
            <Grid item xs={12} sm="auto">
              <Button onClick={handleSync} color="primary" disabled={loading} fullWidth>
                Sync
              </Button>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button onClick={handleDelete} color="error" disabled={loading} fullWidth>
                Remove Integration
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </ClosableDialog>
    </>
  )
}

export default IntegrationDialog
