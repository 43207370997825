import { Price, Product, RDirectory } from '@counsel-project/counsel-auth-api'
import BackIcon from '@mui/icons-material/ArrowBackRounded'
import NextIcon from '@mui/icons-material/ArrowForwardRounded'
import ArrowRightIcon from '@mui/icons-material/ArrowRightAltRounded'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Slide from '@mui/material/Slide'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Cookies from 'js-cookie'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import BlobBackground from '../../components/BlobBackground'
import ClosableDialog from '../../components/ClosableDialog'
import DirectorySelector from '../../components/DirectorySelector'
import CreateDirectoryForm from '../../components/forms/CreateDirectoryForm'
import AcquisitionSourceDialog from '../../components/layout/AcquisitionSourceDialog'
import PageContainer from '../../components/layout/PageContainer'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import useRequireAuth from '../../util/auth/useRequireAuth'
import handleError from '../../util/handleError'
import log from '../../util/logging'
import { logInitiateCheckout, useViewContent } from '../../util/tracking'

type OptionSelectBoxProps = {
  disabled: boolean
}

const OptionSelectBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<OptionSelectBoxProps>(({ theme, disabled }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'start',
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.action.selected}`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  userSelect: 'none',
  transition: 'background-color 0.2s',
  '& .move-left': {
    position: 'relative',
    left: 4,
    transition: 'left 0.2s',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    // Any move-left classes will move the arrow to the left
    '& .move-left': {
      position: 'relative',
      left: 8,
    },
  },
  ...(disabled && {
    pointerEvents: 'none',
    opacity: 0.5,
  }),
}))

type SingleVsOrganizationSelectorProps = {
  loading: boolean
  onClickSingle: () => void
  onClickOrganization: () => void
}

const SingleVsOrganizationSelector = ({
  loading,
  onClickSingle,
  onClickOrganization,
}: SingleVsOrganizationSelectorProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" color="text.primary">
          Are you purchasing an individual license or an organization license?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <OptionSelectBox onClick={onClickSingle} disabled={loading}>
          <Typography variant="h6" color="text.primary">
            Individual License
          </Typography>
          <ArrowRightIcon className="move-left" />
        </OptionSelectBox>
      </Grid>
      <Grid item xs={12}>
        <OptionSelectBox onClick={onClickOrganization} disabled={loading}>
          <Typography variant="h6" color="text.primary">
            Organization License
          </Typography>
          {!loading ? (
            <ArrowRightIcon className="move-left" />
          ) : (
            <CircularProgress sx={{ color: 'background.default' }} className="move-left" />
          )}
        </OptionSelectBox>
      </Grid>
    </Grid>
  )
}

export const getProductPrice = (price: Price, quantity: number) => {
  const billingScheme = price.billing_scheme

  const interval = price.recurring?.interval

  let unitPrice = (price.unit_amount || 0) * quantity
  if (billingScheme === 'tiered') {
    const tiers = (price.tiers || []).sort((a, b) => (b.up_to || 999) - (a.up_to || 999))
    const tier = tiers.find((t) => (t.up_to || 0) <= quantity)
    if (tier) {
      unitPrice = (tier.unit_amount || 0) * quantity + (tier.flat_amount || 0)
    }
  }

  const monthlyPrice = interval === 'year' ? unitPrice / 12 / 100 : unitPrice / 100
  const annualPrice = interval === 'year' ? unitPrice / 100 : (unitPrice / 100) * 12
  const actualPrice = unitPrice / 100

  return { monthlyPrice, annualPrice, actualPrice }
}

type PlanBoxProps = {
  quantity: number
  price: Price
  prices: Price[]
  selected?: boolean
  onClick?: () => void
}

export const PlanBox = ({
  quantity,
  price,
  prices,
  selected = false,
  onClick = () => {},
}: PlanBoxProps) => {
  const interval = price.recurring?.interval

  const { monthlyPrice, annualPrice } = getProductPrice(price, quantity)

  let saved: number | null = null
  const otherPrice = prices.find((p) => p.id !== price.id)
  if (otherPrice) {
    const otherAnnualPrice = getProductPrice(otherPrice, quantity).annualPrice

    saved = otherAnnualPrice - annualPrice
    if (saved < 0) {
      saved = null
    }
  }

  return (
    <Box
      component="button"
      aria-label="Select Plan"
      aria-selected={selected}
      sx={{
        p: 2,
        border: '2px solid',
        borderColor: selected ? 'secondary.main' : 'text.disabled',
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        position: 'relative',
        boxShadow: selected ? 2 : 0,
        backgroundColor: 'transparent',
        width: '100%',
        color: 'text.primary',
        fontStyle: 'normal',
        textDecoration: 'none',
      }}
      onClick={onClick}
    >
      <Typography variant="h4" fontWeight={700} color="text.primary">
        ${saved ? annualPrice : monthlyPrice}
        <Typography component="span" variant="body1">
          /{saved ? 'year' : 'month'}
        </Typography>
      </Typography>
      <Typography variant="body1" fontWeight={500} textAlign="center" color="text.primary">
        ${saved ? monthlyPrice : annualPrice}
        <Typography component="span" variant="body1">
          /{saved ? 'month' : 'year'}
        </Typography>
      </Typography>
      <Typography variant="body1" fontWeight={700} textAlign="center" color="text.primary">
        Billed {interval === 'year' ? 'Annually' : 'Monthly'}
      </Typography>
      {saved && (
        <Box
          sx={{
            position: 'absolute',
            top: -20,
            right: 10,
            width: 64,
            height: 64,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'secondary.main',
            borderRadius: 30,
            boxShadow: 2,
          }}
        >
          <Typography variant="body1" fontWeight={700} color="white" textAlign="center">
            Save
            <br />${saved}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

const getDirection = (thisStep: number, prev: number, step: number) => {
  if (thisStep === prev) {
    if (step > prev) {
      return 'right'
    } else {
      return 'left'
    }
  } else {
    if (step > prev) {
      return 'left'
    } else {
      return 'right'
    }
  }
}

const PurchasePage = () => {
  useTitle('Clinical Notes AI - Purchase Options')
  useViewContent('Purchase')

  const [searchParams] = useSearchParams()
  const m = searchParams.get('m')

  useRequireAuth(`/purchase?${searchParams.toString()}`)

  const navigate = useNavigate()

  const [step, setStep] = useState(-1)
  const [prevStep, setPrevStep] = useState(-1)
  const [shownStep, setShownStep] = useState(-1)

  const [users, setUsers] = useState('1')
  const usersInt = useMemo(() => parseInt(users) || 0, [users])
  const [selectedDirectory, setSelectedDirectory] = useState<RDirectory | null>(null)
  const [selectedPrice, setSelectedPrice] = useState<Price | null>(null)
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState<Product | null>(null)
  const [prices, setPrices] = useState<Price[]>([])
  const [contactUsDialogOpen, setContactUsDialogOpen] = useState(false)
  const [showCreateDirectory, setShowCreateDirectory] = useState(false)
  const [canTrial, setCanTrial] = useState(true)

  // Check if the user can trial
  const populateCanTrial = useCallback(async () => {
    try {
      await checkToken()

      const res = await authRequest.user.subscriptions.canTrial({
        token: '',
      })

      setCanTrial(res.canTrial)
    } catch (err) {
      log.error(err)
    }
  }, [])

  useEffect(() => {
    populateCanTrial()
  }, [populateCanTrial])

  const populateInitialInformation = useCallback(async () => {
    try {
      window.scrollTo(0, 0)

      await checkToken()

      // List the products available
      const res = await authRequest.products.list()

      // Find the product that matches the search parameters
      const productResult = res.products.find((p) => p.product.id === searchParams.get('productId'))

      if (!productResult) {
        toast.error('Product not found')
        navigate('/pricing')
        return
      }

      setProduct(productResult.product)
      setPrices(productResult.prices.filter((p) => p.metadata?.shown === 'true'))
      setSelectedPrice(
        productResult.prices.sort((a, b) => (a.recurring?.interval === 'year' ? -1 : 1))[0]
      )

      setUsers(productResult.product.metadata.users?.toString() || '1')

      setStep(0)
      setShownStep(0)
    } catch (err) {
      log.error(err)
    }
  }, [navigate, searchParams])

  useEffect(() => {
    populateInitialInformation()
  }, [populateInitialInformation])

  const handleIncrementStep = useCallback(
    (n = 1) => {
      setPrevStep(step)
      setStep((prev) => prev + n)
    },
    [step]
  )

  const handleDecrementStep = useCallback(
    (n = 1) => {
      setPrevStep(step)
      setStep((prev) => prev - n)
    },
    [step]
  )

  const handleChangeUsers = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (loading) return
      const n = parseInt(e.target.value)
      if (!n) {
        setUsers('')
        return
      }
      setUsers(`${n}`)
    },
    [loading]
  )

  const handleCreateNewOrg = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    navigate('/organizations/new')
  }

  const handleConfirmStep0 = useCallback(
    async (directory: RDirectory) => {
      try {
        window.scrollTo(0, 0)

        if (!product) {
          toast.error('Please verify your information before continuing')
          return
        }

        setSelectedDirectory(directory)
        handleIncrementStep(1)
      } catch (err) {
        log.error(err)
      } finally {
        setLoading(false)
      }
    },
    [product, handleIncrementStep]
  )

  const startCheckout = useCallback(
    async (priceId: string, quantity: number, directoryId?: string) => {
      try {
        if (!product) return

        setLoading(true)

        await checkToken()

        let successUrl = `${window.location.origin}/purchase/success?type=${product.metadata.type}&quantity=${quantity}&priceId=${priceId}`
        if (m) {
          successUrl += `&m=${m}`
        }

        let cancelUrl = `${window.location.origin}/purchase?productId=${product.id}`
        if (m) {
          cancelUrl += `&m=${m}`
        }

        const res = await authRequest.user.subscriptions.checkout.init({
          token: '',
          priceId,
          quantity,
          directoryId,
          successUrl,
          cancelUrl,
          referral:
            'promotekit_referral' in window && typeof window.promotekit_referral === 'string'
              ? window.promotekit_referral
              : undefined,
        })

        Cookies.set('checkoutSessionId', res.sessionId, {
          expires: 1,
          secure: process.env.NODE_ENV !== 'development',
        })
        Cookies.set('checkoutSessionAmount', res.amountTotal.toString(), {
          expires: 1,
          secure: process.env.NODE_ENV !== 'development',
        })

        logInitiateCheckout({
          productName: product.metadata.type || 'Free',
          productPrice: res.amountTotal / 100,
          quantity,
          amountTotal: res.amountTotal / 100,
        })

        window.location.href = res.url
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    },
    [product, m]
  )

  const handleConfirmStep1 = useCallback(async () => {
    try {
      setLoading(true)

      window.scrollTo(0, 0)

      if (!product) return

      // If the license they are trying to purchase is the same as the active license
      if (selectedDirectory) {
        await checkToken()

        const { total } = await authRequest.user.directories.roles.countOccupiedSeats({
          directoryId: selectedDirectory._id,
          token: '',
        })

        // If the user is trying to purchase fewer users than the number of users in the organization
        if (usersInt < total) {
          toast.error(
            'You cannot select fewer users than the number of users in the organization',
            {
              id: 'min-users',
            }
          )
          return
        }
      }

      // If the user is trying to purchase an organization license with less than 2 users
      if (usersInt < 2) {
        toast.error('For an organization license, you must select more than 1 user', {
          id: 'min-users',
        })
        return
      }

      // Contact us if its a large organization
      if (usersInt > 9000) {
        setContactUsDialogOpen(true)
        return
      }

      // If there is only one price, start the checkout
      if (prices.length === 1) {
        startCheckout(prices[0].id, usersInt, selectedDirectory?._id)
      } else {
        handleIncrementStep(1)
      }
    } catch (err) {
      log.error(err)
    } finally {
      setLoading(false)
    }
  }, [product, usersInt, handleIncrementStep, startCheckout, selectedDirectory, prices])

  const handleConfirmStep2 = useCallback(() => {
    window.scrollTo(0, 0)

    if (!selectedPrice) return
    if (!product) return

    startCheckout(selectedPrice.id, usersInt, selectedDirectory?._id)
  }, [selectedPrice, usersInt, selectedDirectory, product, startCheckout])

  const handleBackStep1 = useCallback(() => {
    window.scrollTo(0, 0)

    handleDecrementStep(1)
    setShowCreateDirectory(false)
  }, [handleDecrementStep])

  const handleBackStep2 = useCallback(() => {
    window.scrollTo(0, 0)

    handleDecrementStep(2)
    setShowCreateDirectory(false)
  }, [handleDecrementStep])

  const handleClickSingle = () => {
    setSelectedDirectory(null)
    setUsers('1')
    handleIncrementStep(2)
  }

  const handleClickOrganization = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const directories = await authRequest.user.directories.list({
        token: '',
        limit: 2,
      })

      if (directories.results.length === 0) {
        setShowCreateDirectory(true)
        return
      }

      if (directories.results.length === 1) {
        setSelectedDirectory(directories.results[0])
      }

      handleIncrementStep(1)
      return
    } catch (err) {
      log.error(err)
    } finally {
      setLoading(false)
    }
  }, [handleIncrementStep])

  return (
    <BlobBackground>
      <PageContainer>
        <Slide
          direction={getDirection(0, prevStep, step)}
          in={shownStep === 0 && step === 0}
          mountOnEnter
          unmountOnExit
          onExited={() => setShownStep(step)}
          timeout={{ enter: 200, exit: 300 }}
        >
          <Paper sx={{ p: 2 }} elevation={4}>
            <Box>
              {!showCreateDirectory ? (
                <SingleVsOrganizationSelector
                  loading={loading}
                  onClickOrganization={handleClickOrganization}
                  onClickSingle={handleClickSingle}
                />
              ) : (
                <CreateDirectoryForm onSubmit={handleConfirmStep0} />
              )}
            </Box>
          </Paper>
        </Slide>
        <Slide
          direction={getDirection(1, prevStep, step)}
          in={shownStep === 1 && step === 1}
          mountOnEnter
          unmountOnExit
          onExited={() => setShownStep(step)}
          timeout={{ enter: 200, exit: 300 }}
        >
          <Paper sx={{ p: 2 }} elevation={4}>
            <Typography variant="h6" textAlign="center">
              Membership Information
            </Typography>
            <Typography variant="body1" textAlign="center" color="text.secondary">
              Lets confirm your membership information
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" color="text.secondary">
                    Number Of Clinicians
                  </Typography>
                  <TextField
                    fullWidth
                    value={users}
                    onChange={handleChangeUsers}
                    variant="outlined"
                    aria-label="users"
                    type="number"
                  />
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{ mt: 1 }}
                    fontStyle="italic"
                  >
                    How many clinicians are on your team?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" color="text.secondary">
                    Your Organization
                  </Typography>
                  <DirectorySelector value={selectedDirectory} onChange={setSelectedDirectory} />
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{ mt: 1 }}
                    fontStyle="italic"
                  >
                    What organization should we apply this membership for?{' '}
                    <Link
                      component="a"
                      underline="none"
                      href="/organizations/new"
                      onClick={handleCreateNewOrg}
                    >
                      Create a new organization
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2} sx={{ mt: 1 }} justifyContent="space-between">
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<BackIcon />}
                  onClick={handleBackStep1}
                  disabled={loading}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  endIcon={<NextIcon />}
                  onClick={handleConfirmStep1}
                  disabled={loading || !selectedDirectory}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Slide>
        <Slide
          direction={getDirection(2, prevStep, step)}
          in={shownStep === 2 && step === 2}
          mountOnEnter
          unmountOnExit
          onExited={() => setShownStep(step)}
          timeout={{ enter: 200, exit: 300 }}
        >
          <Paper sx={{ p: 2 }} elevation={4}>
            <Typography variant="h6" textAlign="center">
              Select Your Plan
            </Typography>
            <Typography variant="body1" textAlign="center" color="text.secondary">
              Save money by paying annually
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                {prices
                  ?.sort((a, b) => (a.recurring?.interval === 'year' ? 1 : -1))
                  .map((price, index) => (
                    <Grid item xs={12} md={6} key={index + '-plan'}>
                      <PlanBox
                        quantity={usersInt}
                        prices={prices}
                        price={price}
                        selected={selectedPrice?.id === price.id}
                        onClick={() => setSelectedPrice(price)}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
            {product?.metadata.freeTrial && canTrial && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body1" textAlign="center" color="text.secondary">
                  Only billed after {product?.metadata.freeTrial} free trial
                </Typography>
              </Box>
            )}
            {product && (
              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography component="span" textAlign="center" variant="h6" fontWeight={700}>
                  {product.metadata.type} Membership
                  {usersInt > 1 ? ' For ' + usersInt + ' Users' : ''}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ marginTop: -2 }}>
                  <ul>
                    <li>
                      {product.metadata.dictation === 'unlimited'
                        ? 'Unlimited Dictations'
                        : parseInt(product.metadata.dictation) * usersInt + ' Dictation Credits'}
                    </li>
                    <li>
                      {product.metadata.liveSessions === 'unlimited'
                        ? 'Unlimited Live Sessions'
                        : parseInt(product.metadata.liveSessions) * usersInt +
                          ' Live Session Credits'}
                    </li>
                    <li>
                      {product.metadata.documents === 'unlimited'
                        ? 'Unlimited Documents'
                        : parseInt(product.metadata.documents) * usersInt + ' Documents Credits'}
                    </li>
                  </ul>
                </Typography>
              </Box>
            )}
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<BackIcon />}
                  onClick={handleBackStep2}
                  disabled={loading}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  endIcon={<NextIcon />}
                  onClick={handleConfirmStep2}
                  disabled={loading}
                >
                  {`Pay ${selectedPrice?.recurring?.interval === 'month' ? 'Monthly' : 'Annually'}`}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Slide>
      </PageContainer>
      <ClosableDialog
        titleText="Need more users?"
        open={contactUsDialogOpen}
        onClose={() => setContactUsDialogOpen(false)}
      >
        <DialogContent>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
            It looks like you've selected a lot of users. We would love to give you a discount.
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Please contact us at{' '}
            <Link component="a" href="mailto:support@clinicalnotes.ai">
              support@clinicalnotes.ai
            </Link>{' '}
            so we can discuss your needs.
          </Typography>
        </DialogContent>
      </ClosableDialog>
      <AcquisitionSourceDialog />
    </BlobBackground>
  )
}

export default PurchasePage
