import {
  RIntegrationPatient,
  IntegrationPatientSortableFields,
} from '@counsel-project/counsel-ehr-api'
import IntegrationPatientRow from './IntegrationPatientRow'
import TableBase from './TableBase'
import useMediaQuery from '@mui/material/useMediaQuery'

export type IntegrationPatientTableProps = {
  searchDisabled?: boolean
  rows: RIntegrationPatient[]
  page: number
  rowsPerPage: number
  rowsPerPageOptions: number[]
  total: number
  loaded: boolean
  sort: IntegrationPatientSortableFields
  direction: 'asc' | 'desc'
  onChangeSort?: (sort: IntegrationPatientSortableFields, direction: 'asc' | 'desc') => void
  onChangePage?: (page: number) => void
  onChangeRowsPerPage?: (rowsPerPage: number) => void
  onClickRow?: (row: RIntegrationPatient) => void
  onSubmitSearch?: (search: string) => void
}

const IntegrationPatientTable = ({
  searchDisabled,
  rows,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  total,
  loaded,
  sort,
  direction,
  onChangeSort,
  onChangePage,
  onChangeRowsPerPage,
  onClickRow,
  onSubmitSearch,
}: IntegrationPatientTableProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <TableBase
      searchDisabled={searchDisabled}
      columns={[
        { field: 'name', label: 'Name', sortable: true, align: 'left' },
        {
          field: 'updatedAt',
          label: 'MRN',
          sortable: true,
          align: 'right',
          hidden: isMobile,
        },
      ]}
      rows={rows}
      renderRow={(row: RIntegrationPatient) => (
        <IntegrationPatientRow key={row._id} isMobile={isMobile} data={row} onClick={onClickRow} />
      )}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      total={total}
      loaded={loaded}
      sort={sort}
      direction={direction}
      onChangeSort={onChangeSort as any}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSubmitSearch={onSubmitSearch}
      noContentMessage="No patients found. This could be due to not having the correct permissions in Kipu to view patients, or if the integration has not synced yet."
    />
  )
}

export default IntegrationPatientTable
