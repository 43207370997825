import { RRole } from '@counsel-project/counsel-auth-api'
import Typography from '@mui/material/Typography'
import { useCallback, useMemo } from 'react'
import PermissionChip from '../chips/PermissionChip'
import StyledTableCell from './StyledTableCell'
import StyledTableRow from './StyledTableRow'
import Checkbox from '@mui/material/Checkbox'

export type RoleRowProps = {
  isMobile?: boolean
  data: RRole
  showPermission?: boolean
  checked: boolean
  onClick?: (data: RRole) => void
  onCheck: (id: string) => void
}

const RoleRow = ({ isMobile, data, showPermission, onClick, checked, onCheck }: RoleRowProps) => {
  const { permission, priority, email, groupName } = data

  const displayName = useMemo(() => {
    if (email) return email
    return groupName + ' (Group)'
  }, [groupName, email])

  const handleCheck = useCallback(
    (e: React.MouseEvent<HTMLTableCellElement>) => {
      e.stopPropagation()
      onCheck(data._id)
    },
    [data._id, onCheck]
  )

  return (
    <StyledTableRow sx={{ cursor: 'default' }}>
      <StyledTableCell onClick={handleCheck}>
        <Checkbox checked={checked} size="small" />
      </StyledTableCell>
      <StyledTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
        onClick={() => onClick?.(data)}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {displayName}
          {data.invited ? ' (Invited)' : ''}
        </Typography>
      </StyledTableCell>
      {showPermission && (
        <StyledTableCell align="right" onClick={() => onClick?.(data)}>
          <PermissionChip permission={permission} priority={priority} />
        </StyledTableCell>
      )}
    </StyledTableRow>
  )
}

export default RoleRow
