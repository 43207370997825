import { RIntegration } from '@counsel-project/counsel-ehr-api'
import { IntegrationSettings } from '@counsel-project/counsel-ehr-api/dist/common/database/RIntegration'
import { useCallback, useMemo, useState } from 'react'
import checkToken from '../../../util/auth/checkToken'
import { ehrRequest } from '../../../util/api/ehr-api'
import handleError from '../../../util/handleError'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ClosableDialog from '../../../components/ClosableDialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'

type IntegrationSettingsDisplayProps = {
  integration: RIntegration
  onChange: (integration: RIntegration) => void
  onSync: () => void
}

const IntegrationSettingsDisplay = ({
  integration,
  onChange,
  onSync,
}: IntegrationSettingsDisplayProps) => {
  const [loading, setLoading] = useState(false)
  const [syncDialogOpen, setSyncDialogOpen] = useState(false)
  const [stagedResult, setStagedResult] = useState<RIntegration | null>(null)

  const [stagedSettings, setStagedSettings] = useState<IntegrationSettings>(
    integration.settings || {
      respectRestrictedPatients: false,
      restrictPatientsToSegmentation: false,
      syncUsers: false,
      liveSessionPatientFileId: undefined,
    }
  )

  const handleChange = useCallback(
    (key: keyof IntegrationSettings, value: boolean | string) => {
      const newSettings = { ...stagedSettings, [key]: value }

      if (key === 'restrictPatientsToSegmentation' && value) {
        newSettings.syncUsers = true
      }

      if (key === 'respectRestrictedPatients' && value) {
        newSettings.syncUsers = true
      }

      if (key === 'syncUsers' && !value) {
        newSettings.respectRestrictedPatients = false
        newSettings.restrictPatientsToSegmentation = false
      }

      setStagedSettings(newSettings)
    },
    [stagedSettings]
  )

  const handleSave = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const { result } = await ehrRequest.integrations.update({
        token: '',
        integrationId: integration._id,
        ...stagedSettings,
      })

      setStagedResult(result)

      if (stagedSettings.syncUsers && !integration.settings?.syncUsers) {
        setSyncDialogOpen(true)
      } else {
        onChange(result)
      }
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [integration._id, stagedSettings, onChange, integration.settings?.syncUsers])

  const respectRestrictedPatientsDescription = useMemo(() => {
    switch (integration.type) {
      case 'kipu':
        return {
          syncUsers: [
            'Sync Clinicians',
            'If enabled, the integration will bring in clinicians from Kipu. This is required for the below settings.',
            'boolean',
          ],
          respectRestrictedPatients: [
            "Respect Kipu's Restricted Patients",
            'If enabled, clinicians will not be able to view patients considered "restricted" in Kipu.',
            'boolean',
          ],
          restrictPatientsToSegmentation: [
            'Restrict clinicians to only view patients in the same location',
            'If enabled, clinicians will only be able to view patients in the same location as the clinician.',
            'boolean',
          ],
          liveSessionPatientFileId: [
            'Require file on-record for live sessions',
            'If included, clinicians will only be able to start live sessions for patients with this file ID on-record. You can include comma-separated values if you have multiple consent forms.',
            'string',
            'Enter an evaluation / consent form ID here',
          ],
        }
      default:
        return {
          respectRestrictedPatients: null,
          restrictPatientsToSegmentation: null,
          syncUsers: null,
          liveSessionPatientFileId: null,
        }
    }
  }, [integration.type])

  const handleSync = useCallback(() => {
    setSyncDialogOpen(false)
    if (stagedResult) {
      onChange(stagedResult)
    }
    onSync()
  }, [onSync, stagedResult, onChange])

  return (
    <>
      <Stack>
        {Object.entries(respectRestrictedPatientsDescription).map(([key, value]) =>
          value && value[2] === 'boolean' ? (
            <FormGroup key={key}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stagedSettings[key as keyof IntegrationSettings] as boolean}
                    onChange={() =>
                      handleChange(
                        key as keyof IntegrationSettings,
                        !stagedSettings[key as keyof IntegrationSettings]
                      )
                    }
                  />
                }
                checked={stagedSettings[key as keyof IntegrationSettings] as boolean}
                label={value[0]}
              />
              <Typography variant="body1" color="text.secondary">
                {value[1]}
              </Typography>
            </FormGroup>
          ) : value && value[2] === 'string' ? (
            <>
              <Typography variant="body1" fontWeight="bold" sx={{ mt: 2 }}>
                {value[0]}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 1 }}>
                {value[1]}
              </Typography>
              <TextField
                placeholder={value[3]}
                value={stagedSettings[key as keyof IntegrationSettings] as string}
                onChange={(e) => handleChange(key as keyof IntegrationSettings, e.target.value)}
              />
            </>
          ) : null
        )}
        {Object.entries(respectRestrictedPatientsDescription).some(([key, value]) => value) ? (
          <Button onClick={handleSave} color="primary" disabled={loading} sx={{ mt: 2 }}>
            Save
          </Button>
        ) : (
          <Typography variant="body1" color="text.secondary">
            This integration does not support any additional settings.
          </Typography>
        )}
      </Stack>
      <ClosableDialog
        open={syncDialogOpen}
        onClose={() => setSyncDialogOpen(false)}
        titleText="Re-Sync Integration"
      >
        <DialogContent>
          <Typography>
            For these settings to take effect, you must re-sync the integration.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSyncDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSync} color="primary" variant="contained">
            Sync
          </Button>
        </DialogActions>
      </ClosableDialog>
    </>
  )
}

export default IntegrationSettingsDisplay
