import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ClosableDialog from '../../components/ClosableDialog'
import MicrophonePermissionDialog from '../../components/audio/MicrophonePermissionDialog'
import getIsMobile from '../../util/getIsMobile'
import useAvailableAudioDevices from '../../util/recording/useAvailableAudioDevices'
import { openMacOSApp } from '../../util/auth/macOSApp'
import { useNavigate, useSearchParams } from 'react-router-dom'
import handleError from '../../util/handleError'
import { LanguageCode } from '@counsel-project/counsel-generation-api'
import { RPatient } from '@counsel-project/counsel-transcribe-api'
import { DirectorySettings } from '@counsel-project/counsel-auth-api/dist/common/database/Directory'
import checkToken from '../../util/auth/checkToken'
import { authRequest } from '../../util/api/auth-api'
import { ehrRequest } from '../../util/api/ehr-api'
import { transcribeRequest } from '../../util/api/transcribe-api'
import log from '../../util/logging'
import { usePatientNomenclature } from '../../util'
import PatientSelector from '../../components/PatientSelector'

export type DeviceOptionsSelected = {
  deviceId?: string
  deviceLabel?: string
  browserAudio?: boolean
  directorySettings?: DirectorySettings
}

type AudioSetupDialogProps = {
  open: boolean
  disabled?: boolean
  dictation?: boolean
  onClose: () => void
  onOptionsSelected: (device?: DeviceOptionsSelected) => void
}

const AudioSetupDialog = ({
  open,
  disabled,
  dictation,
  onClose,
  onOptionsSelected,
}: AudioSetupDialogProps) => {
  const [searchParams] = useSearchParams()

  const { devices, permissionState, requestPermission } = useAvailableAudioDevices()
  const [selectedDevice, setSelectedDevice] = useState<MediaDeviceInfo | null>(null)
  const [usingHeadphones, setUsingHeadphones] = useState(false)
  const [telehealthMethod, setTelehealthMethod] = useState<'browser' | 'app' | null>(null)
  const [loading, setLoading] = useState(false)

  const [selectedPatient, setSelectedPatient] = useState<RPatient | null>(null)
  const [noConsentOnFile, setNoConsentOnFile] = useState(false)
  const [directorySettings, setDirectorySettings] = useState<DirectorySettings | null>(null)

  const navigate = useNavigate()

  const isSafari = useMemo(() => /^((?!chrome|android).)*safari/i.test(navigator.userAgent), [])
  // const isMacOS = useMemo(() => navigator.userAgent.includes('Macintosh'), [])
  const isMobile = useMemo(() => getIsMobile(), [])
  const isMacOS = true // For testing

  const enableStartSession = useMemo(() => {
    if (isMobile) return true
    if (telehealthMethod === 'browser' && isSafari) return false
    if (telehealthMethod === 'app' && !isMacOS) return true
    if (usingHeadphones && !telehealthMethod && !isSafari) return false
    if (disabled) return false
    if (dictation) return true
    return directorySettings?.forceLiveSessionPatientSelection ? !!selectedPatient : true
  }, [
    telehealthMethod,
    usingHeadphones,
    disabled,
    isMobile,
    isSafari,
    isMacOS,
    selectedPatient,
    directorySettings,
    dictation,
  ])

  const populateDirectorySettings = useCallback(async () => {
    try {
      if (dictation) {
        setDirectorySettings({})
        return
      }

      await checkToken()

      const { directorySettings: settings } = await authRequest.user.lookup.settings.self({
        token: '',
      })

      setDirectorySettings(settings)
    } catch (err) {
      handleError(err)
      setDirectorySettings({})
    }
  }, [dictation])

  useEffect(() => {
    if (!open) return
    populateDirectorySettings()
  }, [open, populateDirectorySettings])

  const handlePopulateConsentOnFile = useCallback(async () => {
    try {
      if (!directorySettings || !directorySettings.forceLiveSessionPatientSelection) {
        setNoConsentOnFile(false)
        return
      }

      if (dictation) {
        setNoConsentOnFile(false)
        return
      }

      if (!selectedPatient || !selectedPatient.integrationPatientId) {
        setNoConsentOnFile(false)
        return
      }

      setLoading(true)

      await checkToken()

      const { canInitiateLiveSession } =
        await ehrRequest.integrations.patients.canInitiateLiveSession({
          token: '',
          patientId: selectedPatient.integrationPatientId,
        })

      if (!canInitiateLiveSession) {
        setNoConsentOnFile(true)
      }
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [selectedPatient, directorySettings, dictation])

  useEffect(() => {
    if (!open) return

    setLoading(true)

    const timeout = setTimeout(() => {
      handlePopulateConsentOnFile()
    }, 10)

    return () => clearTimeout(timeout)
  }, [open, handlePopulateConsentOnFile])

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true)

      let resultingPatient: RPatient | undefined = selectedPatient || undefined

      if (selectedPatient && !selectedPatient._id) {
        await checkToken()
        // Create the new patient

        try {
          const { result } = await transcribeRequest.patients.create({
            token: '',
            label: selectedPatient.label,
            integrationPatientId: selectedPatient.integrationPatientId,
            source: selectedPatient.source,
          })

          resultingPatient = result
        } catch (err) {
          log.error(err)

          // Get the patient from the database
          const { results } = await transcribeRequest.patients.list.all({
            token: '',
            search: {
              and: [
                {
                  label: selectedPatient.label,
                },
              ],
            },
            limit: 1,
          })

          resultingPatient = results?.[0]
        }
      }

      let directorySettings: DirectorySettings | undefined
      if (resultingPatient) {
        const { directorySettings: foundDirectorySettings } = await transcribeRequest.patients.get({
          token: '',
          patientId: resultingPatient._id,
        })

        if (foundDirectorySettings) {
          directorySettings = foundDirectorySettings
        }
      }

      if (telehealthMethod === 'app') {
        const languageCode: LanguageCode = searchParams.get('language') as LanguageCode
        const patientId = resultingPatient?._id
        const layout = searchParams.get('layout')

        if (!layout) {
          throw new Error('Layout is required')
        }

        await openMacOSApp({ patientId, language: languageCode, layout })
        return navigate('/builder/transcribe-app')
      }

      onOptionsSelected({
        deviceId: selectedDevice?.deviceId,
        deviceLabel: selectedDevice?.label,
        browserAudio: telehealthMethod === 'browser',
        directorySettings,
      })
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [selectedDevice, onOptionsSelected, telehealthMethod, navigate, searchParams, selectedPatient])

  useEffect(() => {
    if (!open) return
    if (isMobile) return
    requestPermission()
  }, [open, requestPermission, isMobile])

  const handleClose = useCallback(async () => {
    onClose()
  }, [onClose])

  const patientNomenclature = usePatientNomenclature()

  const canStartSession = useMemo(() => {
    if (dictation) return true
    return directorySettings?.forceLiveSessionPatientSelection ? !!selectedPatient : true
  }, [selectedPatient, directorySettings, dictation])

  if (open && permissionState === 'denied') {
    return <MicrophonePermissionDialog open={true} onClose={handleClose} />
  }

  return (
    <ClosableDialog open={open} onClose={handleClose} titleText="Confirm Start Session">
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography color="text.primary" fontWeight={500} gutterBottom>
            Choose your {patientNomenclature} for this session
          </Typography>
          <PatientSelector
            value={selectedPatient}
            onChange={setSelectedPatient}
            disabled={disabled}
          />
          {!canStartSession && (
            <Typography color="secondary" fontWeight={500} sx={{ mt: 1 }}>
              Your organization requires that you select a patient for this session.
            </Typography>
          )}
          {noConsentOnFile && canStartSession && (
            <Typography color="secondary" fontWeight={500} sx={{ mt: 1 }}>
              This {patientNomenclature} does not have a consent form on file. Your organization
              requires that you have a consent form on file in your EHR for this session.
            </Typography>
          )}
        </Box>
        {!isMobile && !dictation && (
          <Box sx={{ mb: 2, mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item container>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={usingHeadphones}
                      onChange={(e) => setUsingHeadphones(e.target.checked)}
                    />
                  }
                  label={<Typography variant="body2">Using Telehealth + Headphones?</Typography>}
                />
              </Grid>
              {usingHeadphones && (
                <>
                  {isMacOS && (
                    <>
                      <Grid item xs={6}>
                        <Button
                          variant={telehealthMethod === 'browser' ? 'contained' : 'text'}
                          color="primary"
                          fullWidth
                          onClick={() => setTelehealthMethod('browser')}
                        >
                          Using Telehealth In-Browser
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant={telehealthMethod === 'app' ? 'contained' : 'text'}
                          color="primary"
                          fullWidth
                          onClick={() => setTelehealthMethod('app')}
                        >
                          Using Telehealth In-App
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        {telehealthMethod === 'browser' ? (
                          <Typography variant="body2" color="text.secondary">
                            Use this option if you are running your telehealth session through your
                            browser (Zoom through chrome etc.). You will be prompted to share the
                            tab or screen that your Telehealth session is in.
                          </Typography>
                        ) : telehealthMethod === 'app' ? (
                          <>
                            <Typography variant="body1" color="text.primary">
                              Use this option if you are running your telehealth session through an
                              application on your desktop like Zoom.
                            </Typography>
                            <Typography variant="body1" color="text.primary" sx={{ mt: 1 }}>
                              For this option to work, you must have the{' '}
                              <strong>Clinical Notes AI Audio Capture</strong> application installed
                              on your desktop.
                            </Typography>
                            <Typography
                              variant="body1"
                              color="text.primary"
                              sx={{ mt: 1 }}
                              fontWeight={500}
                            >
                              You can download it{' '}
                              <Link href="/clinical-notes-ai-audio-capture-app.pkg" target="_blank">
                                here
                              </Link>
                            </Typography>
                          </>
                        ) : null}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Collapse
                      in={
                        (telehealthMethod === 'browser' && usingHeadphones) ||
                        (!telehealthMethod && usingHeadphones && !isMacOS)
                      }
                    >
                      <Box>
                        <Typography
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                          sx={{ mt: 2 }}
                        >
                          Ensure that the share audio option is available and checked at the bottom
                          right.
                          <br />
                          If you share a window the session will not capture.
                        </Typography>
                        <img
                          src="/share-tab-screenshot.png"
                          alt="Share Tab Screenshot"
                          style={{
                            height: '300px',
                            marginTop: 16,
                            borderRadius: 4,
                            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                          }}
                        />
                      </Box>
                    </Collapse>
                  </Grid>
                </>
              )}
              {isSafari && usingHeadphones && telehealthMethod !== 'app' && (
                <Grid item xs={12}>
                  <Typography variant="body2" color="error" fontWeight={500}>
                    It is recommended to use Chrome for Clinical Notes AI during telehealth
                    sessions.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!enableStartSession || loading}
        >
          Start Session
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default AudioSetupDialog
