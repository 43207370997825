import { RDirectory } from '@counsel-project/counsel-auth-api'
import React, { useCallback, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import IntegrationCard from './IntegrationCard'
import MoreIntegrationsCard from './MoreIntegrationsCard'
import KipuCredentialsDialog from './integrations/KipuCredentialsDialog'
import { RIntegration } from '@counsel-project/counsel-ehr-api'
import checkToken from '../../util/auth/checkToken'
import { ehrRequest } from '../../util/api/ehr-api'
import handleError from '../../util/handleError'
import Divider from '@mui/material/Divider'
import IntegrationItem from './integrations/IntegrationItem'
import IntegrationDialog from './integrations/IntegrationDialog'
import AdvancedMDCredentialsDialog from './integrations/AdvancedMDCredentialsDialog'

type OrganizationIntegrationsProps = {
  directory: RDirectory
  seatsLeft: number
  onRefreshUsers: () => void
}

const OrganizationIntegrations = ({
  directory,
  seatsLeft,
  onRefreshUsers,
}: OrganizationIntegrationsProps) => {
  const [kipuOpen, setKipuOpen] = useState(false)
  const [advancedMDOpen, setAdvancedMDOpen] = useState(false)
  const [integrations, setIntegrations] = useState<RIntegration[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [integrationOpen, setIntegrationOpen] = useState(false)
  const [selectedIntegration, setSelectedIntegration] = useState<RIntegration | null>(null)

  const populateIntegrations = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const { results, total } = await ehrRequest.integrations.list({
        token: '',
        directoryId: directory._id,
      })

      setIntegrations(results)
      setTotal(total)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [directory._id])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateIntegrations()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateIntegrations])

  const handleOnIntegrationCreation = () => {
    populateIntegrations()
    setKipuOpen(false)
    setAdvancedMDOpen(false)
  }

  const handleOnDeleted = () => {
    populateIntegrations()
    setIntegrationOpen(false)
  }

  const integrationClickedHandler = (integration: RIntegration) => () => {
    setSelectedIntegration(integration)
    setIntegrationOpen(true)
  }

  const handleUpdateIntegration = useCallback((updatedIntegration: RIntegration) => {
    setIntegrations((prev) =>
      prev.map((i) => (i._id === updatedIntegration._id ? updatedIntegration : i))
    )
  }, [])

  return (
    <Box>
      {integrations.length !== 0 && (
        <Paper sx={{ p: 2, mt: 2, minHeight: 240 }} elevation={0}>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="h6" textAlign="center">
                Current Integrations
              </Typography>
              <Typography variant="body1" color="text.secondary" textAlign="center">
                {total} integration{total !== 1 && 's'} connected
              </Typography>
            </Grid>
            {integrations.map((integration) => (
              <Grid item key={integration._id}>
                <IntegrationItem
                  integration={integration}
                  onClick={integrationClickedHandler(integration)}
                  onUpdateIntegration={handleUpdateIntegration}
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}
      <Paper sx={{ p: 2, mt: 2, minHeight: 240 }} elevation={0}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" textAlign="center">
              Add Integration
            </Typography>
            <Typography variant="body1" color="text.secondary" textAlign="center">
              Select a system to integrate with Clinical Notes AI
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <IntegrationCard
              img="/integrations/kipu_integration.svg"
              title="Kipu"
              description="Use patients and templates from Kipu and push documentation"
              onClick={() => setKipuOpen(true)}
            />
          </Grid>
          <Grid item xs={6}>
            <IntegrationCard
              img="/integrations/advancedmd_integration.svg"
              title="Advanced MD"
              description="Use patients and templates from Advanced MD and push documentation"
              onClick={() => setAdvancedMDOpen(true)}
            />
          </Grid>
          <Grid item xs={6}>
            <IntegrationCard
              img="/icons/auto-awesome.svg"
              title="Simple Practice"
              description="Integrate forms, clients and templates from Simple Practice"
              onClick={() => {
                window.open(
                  'https://chromewebstore.google.com/detail/clinical-notes-ai/epehnbnhpgdckdeiclihbcoihhjnmicf',
                  '_blank'
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <IntegrationCard
              img="/icons/auto-awesome.svg"
              title="Therapy Notes"
              description="Pull documentation, clients and templates from Therapy Notes"
              onClick={() => {
                window.open(
                  'https://chromewebstore.google.com/detail/clinical-notes-ai/epehnbnhpgdckdeiclihbcoihhjnmicf',
                  '_blank'
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <IntegrationCard
              img="/icons/auto-awesome.svg"
              title="Not Here?"
              description="Contact us to request a custom integration"
              onClick={() => {}}
            />
          </Grid>
        </Grid>
        <KipuCredentialsDialog
          directoryId={directory._id}
          open={kipuOpen}
          onClose={() => setKipuOpen(false)}
          onCreated={handleOnIntegrationCreation}
        />
        <AdvancedMDCredentialsDialog
          directoryId={directory._id}
          open={advancedMDOpen}
          onClose={() => setAdvancedMDOpen(false)}
          onCreated={handleOnIntegrationCreation}
        />
        {selectedIntegration && (
          <IntegrationDialog
            seatsLeft={seatsLeft}
            open={integrationOpen}
            onClose={() => setIntegrationOpen(false)}
            integration={selectedIntegration}
            onDelete={handleOnDeleted}
            onUpdateIntegration={handleUpdateIntegration}
            onRefreshUsers={onRefreshUsers}
          />
        )}
      </Paper>
    </Box>
  )
}

export default OrganizationIntegrations
