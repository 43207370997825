import {
  IntegrationUserSortableFields,
  RIntegrationUser,
} from '@counsel-project/counsel-ehr-api/dist/common/database/RIntegrationUser'
import { useCallback, useEffect, useState } from 'react'
import IntegrationUserTable from '../../../components/tables/IntegrationUserTable'
import { ehrRequest } from '../../../util/api/ehr-api'
import checkToken from '../../../util/auth/checkToken'
import handleError from '../../../util/handleError'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import BackIcon from '@mui/icons-material/ArrowBackRounded'
import Chip from '@mui/material/Chip'

type IntegrationUsersDisplayProps = {
  integrationId: string
}

const IntegrationUsersDisplay = ({ integrationId }: IntegrationUsersDisplayProps) => {
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState<IntegrationUserSortableFields>('name')
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc')
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [users, setUsers] = useState<RIntegrationUser[]>([])
  const [selectedUser, setSelectedUser] = useState<RIntegrationUser | null>(null)

  const populatePatients = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const { results, total } = await ehrRequest.integrations.users.listByIntegration({
        token: '',
        integrationId,
        offset: page * limit,
        limit,
        search: search
          ? {
              and: [{ name: search, $fuzzy: true }],
            }
          : undefined,
        sort,
        direction,
      })

      setUsers(results)
      setTotal(total)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [integrationId, page, limit, search, sort, direction])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populatePatients()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populatePatients])

  if (selectedUser) {
    return (
      <Box>
        <Grid container alignItems="center" sx={{ mb: 2 }} spacing={2}>
          <Grid item>
            <Button onClick={() => setSelectedUser(null)}>
              <BackIcon />
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="h6">{selectedUser.name}</Typography>
          </Grid>
        </Grid>
        {!!selectedUser?.email && (
          <Typography variant="body1">
            <strong>Email:</strong> {(selectedUser?.email as string) || 'N/A'}
          </Typography>
        )}
        {!!Array.isArray(selectedUser?.metadata?.locations) &&
          selectedUser?.metadata?.locations.length > 0 && (
            <>
              <Typography variant="body1">
                <strong>Locations:</strong>{' '}
              </Typography>
              <Grid container spacing={1} sx={{ mb: 2, mt: 0.5 }}>
                {selectedUser?.metadata?.locations.map((location) => (
                  <Grid item key={location.id}>
                    <Chip label={location.name + ' (' + location.id + ')'} size="small" />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        {!!selectedUser?.identifier && (
          <Typography variant="body1">
            <strong>Identifier:</strong> {(selectedUser?.identifier as string) || 'N/A'}
          </Typography>
        )}
        {!!selectedUser?.updatedAt && (
          <Typography variant="body1">
            <strong>Last Synced:</strong> {selectedUser?.updatedAt}
          </Typography>
        )}
      </Box>
    )
  }

  return (
    <IntegrationUserTable
      rows={users}
      page={page}
      rowsPerPage={limit}
      total={total}
      loaded={!loading}
      sort={sort}
      direction={direction}
      onChangeSort={(sort, dir) => {
        setSort(sort)
        setDirection(dir)
      }}
      onChangePage={setPage}
      onChangeRowsPerPage={setLimit}
      onSubmitSearch={setSearch}
      onClickRow={setSelectedUser}
      rowsPerPageOptions={[]}
    />
  )
}

export default IntegrationUsersDisplay
