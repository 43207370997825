import { useState } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Button, { ButtonProps } from '@mui/material/Button'
import DownChevronIcon from '@mui/icons-material/ExpandMoreRounded'
import { alpha } from '@mui/material/styles'

type FlippableChevronProps = {
  flipped: boolean
}

const FlippableChevron = styled(DownChevronIcon, {
  shouldForwardProp: (prop) => prop !== 'flipped',
})<FlippableChevronProps>(({ flipped }) => ({
  transform: flipped ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'transform 0.2s ease-in-out',
}))

const TransparentButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}))

type ExpandableButtonContainerProps = {
  disabled?: boolean
}

const ExpandableButtonContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<ExpandableButtonContainerProps>(({ theme, disabled }) => ({
  backgroundColor: !disabled ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
  borderRadius: theme.shape.borderRadius + 'px',
  cursor: 'default',
}))

type ExpandingButtonProps = ButtonProps & {
  text: string
}

const ExpandingButton = ({ children, text, ...props }: ExpandingButtonProps) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <ExpandableButtonContainer disabled={props.disabled}>
      <TransparentButton
        fullWidth
        endIcon={<FlippableChevron flipped={expanded} />}
        onClick={() => setExpanded((prev) => !prev)}
        {...props}
      >
        {text}
      </TransparentButton>
      <Collapse in={expanded}>{children}</Collapse>
    </ExpandableButtonContainer>
  )
}

export default ExpandingButton
