import Header from '@editorjs/header'
import CheckList from '@editorjs/checklist'
import CheckListTable from './modules/CheckListTable'
import ExportBox from './modules/ExportBox'
import PageBreak from './modules/PageBreak'
import Paragraph from '@editorjs/paragraph'

export type GetEditorToolsOptions = {
  onEmail: (text: string) => void
  onCreateNewNote: (text: string) => void
}

export const getEditorTools = ({ onEmail, onCreateNewNote }: GetEditorToolsOptions): any => ({
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      preserveBlank: true,
    },
  },
  header: { class: Header, inlineToolbar: true },
  checklist: CheckList,
  table: {
    class: CheckListTable,
    inlineToolbar: true,
    config: {
      rows: 2,
      cols: 2,
    },
  },
  pageBreak: PageBreak,
  exportBox: {
    class: ExportBox,
    config: {
      onEmail,
      onCreateNewNote,
    },
  },
})
