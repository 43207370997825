import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'

type OverlayBoxProps = {
  absolute?: boolean
}

const OverlayBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'absolute',
})<OverlayBoxProps>(({ theme, absolute }) => ({
  position: absolute ? 'absolute' : 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: 9999,
}))

const OverlayChildrenBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

type OverlayLoaderProps = {
  children?: React.ReactNode
  loaded?: boolean
  absolute?: boolean
}

const OverlayLoader = ({ children, loaded, absolute }: OverlayLoaderProps) => {
  return (
    <OverlayBox absolute={absolute}>
      <OverlayChildrenBox>{children}</OverlayChildrenBox>
      {!loaded && <CircularProgress />}
    </OverlayBox>
  )
}

export default OverlayLoader
