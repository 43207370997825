import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import getIsMobile from '../../util/getIsMobile'
import useAvailableAudioDevices from '../../util/recording/useAvailableAudioDevices'
import ClosableDialog from '../ClosableDialog'
import MicrophonePermissionDialog from './MicrophonePermissionDialog'
import { RPatient } from '@counsel-project/counsel-transcribe-api'
import PatientSelector from '../PatientSelector'
import { usePatientNomenclature } from '../../util'
import handleError from '../../util/handleError'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import log from '../../util/logging'
import { ehrRequest } from '../../util/api/ehr-api'
import { DirectorySettings } from '@counsel-project/counsel-auth-api/dist/common/database/Directory'
import { authRequest } from '../../util/api/auth-api'
import LoadingButton from '@mui/lab/LoadingButton'

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export type DeviceOptions = {
  deviceId?: string
  label?: string
  browserAudio?: boolean
  patientId?: string
  directorySettings?: DirectorySettings
}

type SelectAudioDeviceDialogProps = {
  open: boolean
  disabled?: boolean
  dictation?: boolean
  onClose: () => void
  onDeviceSelected: (device?: DeviceOptions) => void
}

const SelectAudioDeviceDialog = ({
  open,
  disabled,
  dictation,
  onClose,
  onDeviceSelected,
}: SelectAudioDeviceDialogProps) => {
  const { devices, permissionState, requestPermission } = useAvailableAudioDevices()
  const [selectedDevice, setSelectedDevice] = useState<MediaDeviceInfo | null>(null)
  const [useDesktopAudio, setUseDesktopAudio] = useState(false)
  const [selectedPatient, setSelectedPatient] = useState<RPatient | null>(null)
  const [loading, setLoading] = useState(false)
  const [noConsentOnFile, setNoConsentOnFile] = useState(false)
  const [directorySettings, setDirectorySettings] = useState<DirectorySettings | null>(null)

  const isMobile = useMemo(() => getIsMobile(), [])

  const populateDirectorySettings = useCallback(async () => {
    try {
      if (dictation) {
        setDirectorySettings({})
        return
      }

      await checkToken()

      const { directorySettings: settings } = await authRequest.user.lookup.settings.self({
        token: '',
      })

      setDirectorySettings(settings)
    } catch (err) {
      handleError(err)
      setDirectorySettings({})
    }
  }, [dictation])

  useEffect(() => {
    if (!open) return
    populateDirectorySettings()
  }, [open, populateDirectorySettings])

  const handlePopulateConsentOnFile = useCallback(async () => {
    try {
      if (!directorySettings || !directorySettings.forceLiveSessionPatientSelection) {
        setNoConsentOnFile(false)
        return
      }

      if (dictation) {
        setNoConsentOnFile(false)
        return
      }

      if (!selectedPatient || !selectedPatient.integrationPatientId) {
        setNoConsentOnFile(false)
        return
      }

      setLoading(true)

      await checkToken()

      const { canInitiateLiveSession } =
        await ehrRequest.integrations.patients.canInitiateLiveSession({
          token: '',
          patientId: selectedPatient.integrationPatientId,
        })

      if (!canInitiateLiveSession) {
        setNoConsentOnFile(true)
      }
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [selectedPatient, directorySettings, dictation])

  useEffect(() => {
    if (!open) return

    setLoading(true)

    const timeout = setTimeout(() => {
      handlePopulateConsentOnFile()
    }, 10)

    return () => clearTimeout(timeout)
  }, [open, handlePopulateConsentOnFile])

  const handleSubmit = useCallback(async () => {
    try {
      let resultingPatient: RPatient | undefined = selectedPatient || undefined

      if (selectedPatient && !selectedPatient._id) {
        await checkToken()
        // Create the new patient

        try {
          const { result } = await transcribeRequest.patients.create({
            token: '',
            label: selectedPatient.label,
            integrationPatientId: selectedPatient.integrationPatientId,
            source: selectedPatient.source,
          })

          resultingPatient = result
        } catch (err) {
          log.error(err)

          // Get the patient from the database
          const { results } = await transcribeRequest.patients.list.all({
            token: '',
            search: {
              and: [
                {
                  label: selectedPatient.label,
                },
              ],
            },
            limit: 1,
          })

          resultingPatient = results?.[0]
        }
      }

      let directorySettings: DirectorySettings | undefined
      if (resultingPatient) {
        const { directorySettings: foundDirectorySettings } = await transcribeRequest.patients.get({
          token: '',
          patientId: resultingPatient._id,
        })

        if (foundDirectorySettings) {
          directorySettings = foundDirectorySettings
        }
      }

      onDeviceSelected({
        deviceId: selectedDevice?.deviceId,
        label: selectedDevice?.label,
        browserAudio: useDesktopAudio,
        patientId: resultingPatient?._id,
        directorySettings,
      })
    } catch (err) {
      handleError(err)
    }
  }, [selectedDevice, onDeviceSelected, useDesktopAudio, selectedPatient])

  useEffect(() => {
    if (!open) return
    if (isMobile) return
    requestPermission()
  }, [open, requestPermission, isMobile])

  const patientNomenclature = usePatientNomenclature()

  const canStartSession = useMemo(() => {
    if (dictation) return true
    return directorySettings?.forceLiveSessionPatientSelection ? !!selectedPatient : true
  }, [selectedPatient, directorySettings, dictation])

  if (open && permissionState === 'denied') {
    return <MicrophonePermissionDialog open={true} onClose={onClose} />
  }

  return (
    <ClosableDialog
      open={open}
      onClose={onClose}
      titleText="Confirm Start Session"
      closeButtonProps={{ id: 'close-start-session-button' }}
    >
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography color="text.primary" fontWeight={500} gutterBottom>
            Choose your {patientNomenclature} for this session
          </Typography>
          <PatientSelector
            value={selectedPatient}
            onChange={setSelectedPatient}
            disabled={disabled}
          />
          {!canStartSession && (
            <Typography color="secondary" fontWeight={500} sx={{ mt: 1 }}>
              Your organization requires that you select a patient for this session.
            </Typography>
          )}
          {noConsentOnFile && canStartSession && (
            <Typography color="secondary" fontWeight={500} sx={{ mt: 1 }}>
              This {patientNomenclature} does not have a consent form on file. Your organization
              requires that you have a consent form on file in your EHR for this session.
            </Typography>
          )}
        </Box>
        {!isMobile && !dictation && (
          <Box sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useDesktopAudio}
                  onChange={(e) => setUseDesktopAudio(e.target.checked)}
                />
              }
              label={<Typography variant="body2">This is a Telehealth session</Typography>}
            />
            {isSafari ? (
              <Typography variant="body2" color="text.secondary">
                Safari does not support sharing your computer's audio. If you would like to use
                audio from a telehealth session, please use a different browser like chrome.
              </Typography>
            ) : (
              <Typography variant="body2" color="text.secondary">
                You will be prompted to share the tab or screen that your Telehealth session is in.
              </Typography>
            )}
            {!isSafari && (
              <Collapse in={useDesktopAudio}>
                <Box>
                  <Typography variant="body2" color="text.primary" fontWeight={500} sx={{ mt: 2 }}>
                    Ensure that the share audio option is available and checked at the bottom right.
                    <br />
                    <br />
                    If you share a window the session will not capture.
                  </Typography>
                  <img
                    src="/share-tab-screenshot.png"
                    alt="Share Tab Screenshot"
                    style={{
                      height: '300px',
                      marginTop: 16,
                      borderRadius: 4,
                      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                    }}
                  />
                </Box>
              </Collapse>
            )}
          </Box>
        )}
        {/* 
        {devices.length !== 0 && (
          <TextAccordion
            id="select-audio-device"
            title={!selectedDevice ? `Use Default Audio` : 'Select Audio Device'}
          >
            <Grid container spacing={2}>
              {devices.some((d) => !!d.label) &&
                devices.map((device) => (
                  <Grid item key={device.deviceId} xs={12}>
                    <Button
                      variant={selectedDevice?.deviceId === device.deviceId ? 'contained' : 'text'}
                      color="primary"
                      onClick={() => setSelectedDevice(device)}
                      fullWidth
                    >
                      {device.label}
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </TextAccordion>
        )} */}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            disabled || loading || noConsentOnFile || !directorySettings || !canStartSession
          }
          loading={loading}
          id="confirm-start-button"
        >
          Start Session
        </LoadingButton>
      </DialogActions>
    </ClosableDialog>
  )
}

export default SelectAudioDeviceDialog
