import { RIntegrationUser } from '@counsel-project/counsel-ehr-api/dist/common/database/RIntegrationUser'
import Typography from '@mui/material/Typography'
import StyledTableCell from './StyledTableCell'
import StyledTableRow from './StyledTableRow'

export type IntegrationUserRowProps = {
  isMobile?: boolean
  data: RIntegrationUser
  onClick?: (data: RIntegrationUser) => void
}

const IntegrationUserRow = ({ isMobile, data, onClick }: IntegrationUserRowProps) => {
  const { name, email } = data

  return (
    <StyledTableRow onClick={() => onClick?.(data)}>
      <StyledTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {name}
        </Typography>
      </StyledTableCell>
      {!isMobile && (
        <StyledTableCell align="right">
          <Typography
            variant="subtitle2"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {email}
          </Typography>
        </StyledTableCell>
      )}
    </StyledTableRow>
  )
}

export default IntegrationUserRow
