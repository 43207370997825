import PageContainer from '../components/layout/PageContainer'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import DownloadIcon from '@mui/icons-material/DownloadRounded'
import DropdownSection from '../components/layout/DropdownSection'
import Box from '@mui/material/Box'
const logo = '/app/app.png'

const installerImg = '/app/installer.png'

const x64 =
  'https://www.dropbox.com/scl/fi/2yc4vtalyma6lepusgxda/CNAI-Tele-Health-Capture-1.1.3-x64.dmg?rlkey=rfwatiu84fakuvbqk7uifv7k3&st=m45qnxj3&dl=1'

const arm64 =
  'https://www.dropbox.com/scl/fi/3ndn8ztx5jcdu29q0hlis/CNAI-Tele-Health-Capture-1.1.3-arm64.dmg?rlkey=k8tb7h0lc5953v7jaxcu6z4bq&st=e6fpo70x&dl=1'

const DownloadApp = () => {
  const handleDownloadARM = () => {
    window.open(arm64, '_blank')
  }

  const handleDownloadINTEL = () => {
    window.open(x64, '_blank')
  }

  return (
    <PageContainer>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        direction="column"
        spacing={2}
      >
        <Grid item xs={12} md={6}>
          <img src={logo} alt="logo" style={{ width: '100%', height: '400px' }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" textAlign="center">
            Download our MacOS Telehealth Capture App
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }} textAlign="center">
            Download the app to capture audio from your conversations on Zoom, Doxy, or any other
            telehealth platform. Requires MacOS 13.0 or later.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            sx={{ mt: 1 }}
            onClick={handleDownloadARM}
            startIcon={<DownloadIcon />}
          >
            Download For Apple Silicon (M1, M2, M3, M4)
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            sx={{ mt: 1 }}
            onClick={handleDownloadINTEL}
            startIcon={<DownloadIcon />}
          >
            Download For Intel Based Macs
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <DropdownSection
            title="How to Install"
            subtitle="Take these steps to install the app"
            sx={{ mt: 2 }}
          >
            <Box sx={{ maxWidth: '500px' }}>
              <img
                src={installerImg}
                alt="logo"
                style={{ width: '100%', height: '300px', objectFit: 'contain' }}
              />
              <Typography variant="body1" sx={{ mt: 1 }}>
                1. Download the installer from the link above.
              </Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                2. Open the installer and drag the app to your Applications folder.
              </Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                3. Thats it! When starting Telehealth sessions with the web app or in-platform
                widget, the app will automatically start capturing audio.
              </Typography>
            </Box>
          </DropdownSection>
        </Grid>
      </Grid>
    </PageContainer>
  )
}

export default DownloadApp
