import {
  IntegrationUserSortableFields,
  RIntegrationUser,
} from '@counsel-project/counsel-ehr-api/dist/common/database/RIntegrationUser'
import IntegrationUserRow from './IntegrationUserRow'
import TableBase from './TableBase'
import useMediaQuery from '@mui/material/useMediaQuery'

export type IntegrationUserTableProps = {
  searchDisabled?: boolean
  rows: RIntegrationUser[]
  page: number
  rowsPerPage: number
  rowsPerPageOptions: number[]
  total: number
  loaded: boolean
  sort: IntegrationUserSortableFields
  direction: 'asc' | 'desc'
  onChangeSort?: (sort: IntegrationUserSortableFields, direction: 'asc' | 'desc') => void
  onChangePage?: (page: number) => void
  onChangeRowsPerPage?: (rowsPerPage: number) => void
  onClickRow?: (row: RIntegrationUser) => void
  onSubmitSearch?: (search: string) => void
}

const IntegrationUserTable = ({
  searchDisabled,
  rows,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  total,
  loaded,
  sort,
  direction,
  onChangeSort,
  onChangePage,
  onChangeRowsPerPage,
  onClickRow,
  onSubmitSearch,
}: IntegrationUserTableProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <TableBase
      searchDisabled={searchDisabled}
      columns={[
        { field: 'name', label: 'Name', sortable: true, align: 'left' },
        {
          field: 'email',
          label: 'Email',
          sortable: false,
          align: 'right',
          hidden: isMobile,
        },
      ]}
      rows={rows}
      renderRow={(row: RIntegrationUser) => (
        <IntegrationUserRow key={row._id} isMobile={isMobile} data={row} onClick={onClickRow} />
      )}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      total={total}
      loaded={loaded}
      sort={sort}
      direction={direction}
      onChangeSort={onChangeSort as any}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSubmitSearch={onSubmitSearch}
    />
  )
}

export default IntegrationUserTable
